import React, { useEffect, useState, CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NotificationProps {
  message: string;
  type: 'success' | 'error' | 'warning';
  id: number;
  onClose: (id: number) => void;
  index: number; // Add index prop for positioning
}

const Notification: React.FC<NotificationProps> = ({ message, type, id, onClose, index }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log(`Notification ${id} created`);
    // Start the transition to visible state
    const timer = setTimeout(() => setVisible(true), 0);

    const hideTimer = setTimeout(() => {
      console.log(`Notification ${id} starting to hide`);
      setVisible(false);
      setTimeout(() => {
        console.log(`Notification ${id} closing`);
        onClose(id);
      }, 500); // Delay to allow for animation
    }, 5000);

    return () => {
      console.log(`Notification ${id} timers cleared`);
      clearTimeout(timer);
      clearTimeout(hideTimer);
    };
  }, [id, onClose]);

  const style: CSSProperties = {
    top: `${index * 65}px`, // Calculate top position based on index
    boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.4)',
    zIndex: `${10 - index}`,
  };

  const getIcon = (type: 'success' | 'error' | 'warning') => {
    switch (type) {
      case 'success':
        return 'check-circle';
      case 'error':
        return 'times-circle';
      case 'warning':
        return 'circle-exclamation';
      default:
        return 'info-circle';
    }
  };

  return (
    <div className={`notification ${type} ${visible ? 'show' : ''}`} style={style}>
      <FontAwesomeIcon style={{marginRight:'5px'}} className='listed icon' icon={['fas', getIcon(type)]} />
      {message}
    </div>
  );
};

export default Notification;
