import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { UserContext } from '../../universal/context/UserContext';
import SlotsPage from './SlotsPage'; // Assuming SlotsPage is in the same directory
import HreflangsPage from './HreflangsPage';
import SiteListerPage from './SiteListerPage';
import ApplicationPage from './ApplicationPage';
import CasinosPage from './CasinoListPage';

const Main: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [mainPage, setMainPage] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.mainPage) {
      setMainPage(user.mainPage);
    }
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
        navigate('/');
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const renderMainPage = () => {
        return (
          <div className='dashpanel'>
            <div className="main">
              <div className="head">
                {user?.mainPage==="dashboard" && (
                <>
                <h5><span className='usertext'>nomble</span> - Beta 3.4</h5>
                <h1>Hello <span className="usertext">{user?.name} {user?.surname}</span>!</h1>
                <h2>
                  This is your homepage/dashboard, choose a different homepage in the <span className='usertext'>nomble</span> settings or by <Link to='/console/settings'>clicking here</Link>.
                </h2>
                </>
                )}
                {user?.mainPage==="slots" && (
                  <SlotsPage />
                )}
                {user?.mainPage==="hreflangs" && (
                  <HreflangsPage />
                )}
                {user?.mainPage==="sitelister" && (
                  <SiteListerPage />
                )}
                {user?.mainPage==="casinos" && (
                  <CasinosPage />
                )}
              </div>
            </div>
          </div>
        );
  };

  return (
    <div className='main-content'>
      {renderMainPage()}
    </div>
  );
};

export default Main;
