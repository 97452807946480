import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../universal/context/UserContext';
import { browserSessionPersistence } from "firebase/auth";

const Navbar: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const auth = getAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);//loading

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser){
        setUser(null);
        console.log('Redirecting to null {firebase error}.');
        navigate('/');
      }});
  
    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);
  
  useEffect(() => {
    if(user?.role === 'admin'){
      setVisible(true);
    }
    else if (user?.role === 'client'){
      setVisible(false);
      console.log('Redirecting to client app.');
      navigate('/clientapp');
    } 
    else {
      setVisible(false);
    }
  }, [user]);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  }

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null); // Reset user state after logout
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  if(visible){
  return (
    <div className='navparent'>
      <div className={`topnav ${isOpen ? 'responsive' : ''}`} id="myTopnav" ref={navbarRef}>
        <div className='innernav'>
          <div className='navlinks'>
          <Link to='/admin' className='dash' onClick={handleNavbarToggle}>
            <img width={65} src='/DREAM-WHITE.png' alt='DREAM logo' />
          </Link>
            <Link to='/admin' className={location.pathname === '/admin' ? 'navitem active' : 'navitem'} onClick={handleNavbarToggle}>Dashboard</Link>
            <Link to='/admin/users' className={location.pathname === '/admin/users'||location.pathname.startsWith('/admin/modify-user/') ? 'navitem active' : 'navitem'} onClick={handleNavbarToggle}>Users</Link>
            <Link to='/admin/sitemanager' className={location.pathname === '/admin/sitemanager' ? 'navitem active' : 'navitem'} onClick={handleNavbarToggle}>Site Manager</Link>
          </div>

          <div className='navservices'>
            <Link className={location.pathname === '/admin/profile' ? 'navitem profile active' : 'navitem profile'} to="/admin/profile" onClick={handleNavbarToggle}>
              {user?.email}&nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={['fas', 'cog']} />
            </Link>
            <Link to="#" onClick={handleLogout} className="navitem lasticon">Logout</Link>
          </div>
          <Link to="#" className="icon" onClick={handleNavbarToggle}>
            <FontAwesomeIcon icon={['fas', 'bars']} />
          </Link>
        </div>
      </div>
    </div>
  )
  }else{
    return(<></>)
  };
};

export default Navbar;
