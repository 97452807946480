import React, { useState, useEffect, useContext } from 'react';
import  CodeMirror  from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { material } from '@uiw/codemirror-theme-material';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadString } from 'firebase/storage';
import { dbSlots } from '../../App';
import { UserContext } from '../../universal/context/UserContext';
import { useNavigate } from 'react-router-dom';

import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';

const SlotsPage = () => {
  const { showNotification } = useNotification();
  const { showLoader, hideLoader } = useLoader();

  const [scriptContent, setScriptContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedBy, setLastSavedBy] = useState('');
  const [showMessage, setShowMessage] = useState(false);//loading
  const [popupMessage, setPopupMessage] = useState('');

  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    //Check auths
    if (!user?.allowSlotCodeEditing) {
      navigate('/');
    }
  });

  useEffect(() => {
    // Fetch the existing script and initialise scriptContent
    const fetchScript = async () => {
      showLoader();
      const scriptRef = doc(dbSlots, 'slotspage', 'slotscript');
      const scriptDoc = await getDoc(scriptRef);
      if (scriptDoc.exists()) {
        setScriptContent(scriptDoc.data().content || '');
        setLastSavedBy(scriptDoc.data().lastSavedBy || '');
      }
      hideLoader();
    };

    fetchScript();
  }, []);

  const handleSave = async () => {

    setIsSaving(true);
    showLoader();
    const scriptRef = doc(dbSlots, 'slotspage', 'slotscript');
    await setDoc(scriptRef, { content: scriptContent, lastSavedBy: user?.name + " " + user?.surname });

    // Save the script content in a storage bucket
    const storage = getStorage();
    const scriptFileRef = ref(storage, 'slotscript.js');
    await uploadString(scriptFileRef, scriptContent, 'raw', {
      contentType: 'text/javascript',
    });
    hideLoader();
    setIsSaving(false); 
    setLastSavedBy(user?.name + " " + user?.surname);
    showNotification('Script Saved and Deployed!', 'success');
  };

  const handleEditorChange = (value: string) => {
    setScriptContent(value);
  };

  const hideMessage = () => {
    setShowMessage(false);
  };

  return (
    <div className="dashpanel">
    <div className="main">
      <div className="head">
      {showMessage && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>{popupMessage}</b></p>
      <button className='sitemanagerbutton selected' onClick={() => hideMessage()}>OKAY</button>
    </div>
  </div>
)}
        <h1>
          Here you can edit the <span className="usertext">JavaScript</span> for the slots.
        </h1>
        <h2>Do so by changing the code below:</h2>

        <CodeMirror
          value={scriptContent}
          height="350px"
          theme={material}
          extensions={[javascript()]}
          onChange={handleEditorChange}
        />
        <h3>Last saved by <span className="usertext"><u style={{fontWeight:900}}>{lastSavedBy}</u></span></h3>
        <button onClick={handleSave} disabled={isSaving} className="termsbuttonstyleadd" style={{ marginTop: '20px' }}>
          {isSaving ? 'Saving...' : 'Save Script'}
        </button>

      </div>
    </div>
    </div>
  );
};

export default SlotsPage;
