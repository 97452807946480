// universal/components/LoaderContext.tsx
import React, { createContext, useState, useContext, ReactNode, FC } from 'react';

interface LoaderContextType {
    loading: boolean;
    progress: number | null;
    message: string | null;
    showLoader: (message?: string, progress?: number) => void;
    updateProgress: (progress: number) => void;
    hideLoader: () => void;
}

interface LoaderProviderProps {
    children: ReactNode;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: FC<LoaderProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState<number | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const showLoader = (message: string = '', progress: number = 0) => {
        setMessage(message);
        setProgress(progress);
        setLoading(true);
    };

    const updateProgress = (progress: number) => {
        setProgress(progress);
    };

    const hideLoader = () => {
        setLoading(false);
        setProgress(null);
        setMessage(null);
    };

    return (
        <LoaderContext.Provider value={{ loading, progress, message, showLoader, updateProgress, hideLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoader = (): LoaderContextType => {
    const context = useContext(LoaderContext);
    if (context === undefined) {
        throw new Error('useLoader must be used within a LoaderProvider');
    }
    return context;
};
