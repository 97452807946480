// UserContext.tsx or UserContext.js

import React from 'react';

export interface Profile {
  email: string;
  name: string;
  surname: string;
  passwordSet: boolean;
  position: string;
  role: string;
  regdate: Date;
  allowCasinoEditing: boolean;
  allowSlotCodeEditing: boolean;
  allowHreflangSetEditing: boolean;
  allowBackupManagement: boolean;
  mainPage: string;
  actionButton: string;
  casinoEditing: string;
  slotCodeEditing: string;
  managingBackups: string;
  hreflangSetEditing: string;
  mainPageNotice: string,
  navbarIconsEnabled: string
}

interface UserContextProps {
    user: Profile | null;
    setUser: (user: Profile | null) => void;  // This line has been updated
  }
  
  export const UserContext = React.createContext<UserContextProps>({
    user: null,
    setUser: () => {
      throw new Error('setUser function must be overridden');
    },
  });
  
