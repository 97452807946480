import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { dbCasinos } from '../../App';
import { Unsubscribe, collection, addDoc, doc, setDoc, getDoc, getDocs, deleteDoc, onSnapshot, runTransaction, where, query, Firestore, orderBy } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { UserContext } from '../../universal/context/UserContext';
import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';

let InitialPosNr: number;
let PosNr_More_Boolean: boolean = false; 
let PosNr_Less_Boolean: boolean = false;
let More_than_Max: boolean = false;
let unassignedCasinoMaxCheck = false

interface Params extends Record<string, string | undefined> {
  casinoId: string;
}
interface ICasino{
  image: string;
          finalTerms: string;
          positionNumber: number;
          noDepositOffer: string;
          depositBonusOffer: string;
          bonusCode: string;
          onDeposit: string;
          smallTerms: string;
          geoTerms: string;
          displayName: string;
          trackingLink: string;
          PluginLink: string;
          noDepositFreeSpins: string;
          bonusSpins: string;
          bonusSpinCount: number;
          hasBonusCode: string;
          CasinoPicName: string;
}
const ModifyCasino: React.FC = () => {
  const { showLoader, hideLoader } = useLoader();
  const { showNotification } = useNotification();
  const params = useParams<Params>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const casinoId = params.casinoId || '';
  const siteId = searchParams.get('site') || '';
  const geoId = searchParams.get('geo') || '';
  const pageId = searchParams.get('page') || '';
  const [imageError, setImageError] = useState('');


  const navigate = useNavigate();
  const database = dbCasinos;
  const auth = getAuth();

  // Declare state for the casino details
//Image
const [image, setImage] = useState<File | string>('');
//Delete Old image if its changes  
const [imageName, setImageName] = useState<string|null>('');
//Final Terms
const [casinoDataFinalTerms, setCasinoDataFinalTerms] = useState<string>('');
//Position Number
const [casinoDataPositionNumber, setCasinoDataPositionNumber] = useState<number>(0);
//No Deposit Offer
const [casinoDataNoDepositOffer, setCasinoDataNoDepositOffer] = useState<string>('');
//Deposit Bonus Offer
const [casinoDataDepositBonustOffer, setCasinoDataDepositBonustOffer] = useState<string>('');
//Bonus Code
const [casinoDataBonusCode, setCasinoDataBonusCode] = useState<string>('');
//On Deposit
const [casinoDataOnDeposit, setCasinoDataOnDeposit] = useState<string>('');
//Small Terms
const [casinoDataSmallTerms, setCasinoDataSmallTerms] = useState<string>('');
//GEO Terms
const [casinoDataGeoTerms, setCasinoDataGeoTerms] = useState<string>('');
//Display Name
const [casinoDataDisplayName, setCasinoDataDisplayName] = useState<string>('');
//Tracking Link
const [casinoDataTrackingLink, setCasinoDataTrackingLink] = useState<string>('');
//No Deposit Free Spins
const [casinoDataNoDepositFreeSpins, setCasinoDataNoDepositFreeSpins] = useState<string>('');
//Bonus Spins
const [casinoDataBonusSpins, setCasinoDataBonusSpins] = useState<string>('');
//Bonus Spin Count
const [casinoDataBonusSpinCount, setCasinoDataBonusSpinCount] = useState<number>(0);
 //Has Bonus Code
 const [casinoDataHasBonusCode, setCasinoDataHasBonusCode] = useState<string>('');
//Bonus Code Allowed
const [casinoDataBonusCodeAvailable, setCasinoDataBonusCodeAvailable] = useState<boolean>(true);
//Cached bonus code
const [casinoDataCachedBonusCode, setCasinoDataCachedBonusCode] = useState<string>('');


//Casino Data Terms
const [casinoDataTermsItems, setCasinoDataTermsItems] = useState<Array<{ type: 'text' | 'link', content: string, url?: string }>>([]);
  
const setFixBonusCodeForCasinoDataHasBonusCode = (state: string) => {
  console.log("thestate: "+state);
  /*console.log("thestate: "+state);
    if (state === 'No'){
      setCasinoDataCachedBonusCode(casinoDataBonusCode);
      setCasinoDataBonusCode('N/A');
      setCasinoDataBonusCodeAvailable(false);
    }
    else if (state === 'Yes'){
      setCasinoDataBonusCode(casinoDataCachedBonusCode);
      setCasinoDataBonusCodeAvailable(true);
      setCasinoDataCachedBonusCode('');
    }*/
  setCasinoDataHasBonusCode(state);
}
const [showSitesChangedMessage, setShowSitesChangedMessage] = useState(false);
const [showMessage, setShowMessage] = useState(false);

 //Stores Details about User
 const { user } = useContext(UserContext);

 //Intial Calls
 useEffect(() => {
   //Check auths
   if (!user?.allowCasinoEditing) {
     navigate('/');
   }
 });

const hideSitesChangedMessage = () => {
  setShowSitesChangedMessage(false);
  navigate(`/console/?site=${siteId}&geo=${geoId}&page=${pageId}`);
}

const hideMessage = () => {
  setShowMessage(false);
  navigate(`/console/?site=${siteId}&geo=${geoId}&page=${pageId}`);
};



//Image constant
const inputRef = useRef<HTMLInputElement>(null);


  const fetchSingleCasinoData = async () => {
  showLoader();
  
  console.log("Site ID: " + siteId + geoId + pageId + casinoId);
    const casinoRef = doc(database, `/sites/${siteId}/geos/${geoId}/pages/${pageId}/casinos`, casinoId);

    
  try {
    const casinoSnapshot = await getDoc(casinoRef);

    console.log(casinoSnapshot)

    if (casinoSnapshot.exists()) {

      const data = casinoSnapshot.data();
      console.log(data, data.finalTerms,'data')

      console.log(data.CasinoPicName,'CasinoPicName')
      setImage(data?.image || '');
      setImageName(data?.CasinoPicName);
      setCasinoDataFinalTerms(data?.finalTerms || '');
      setCasinoDataPositionNumber(data?.positionNumber || 0);
      InitialPosNr= data?.positionNumber || 0
      setCasinoDataNoDepositOffer(data?.noDepositOffer || '');
      setCasinoDataDepositBonustOffer(data?.depositBonusOffer || '');
      setCasinoDataBonusCode(data?.bonusCode || '');
      setCasinoDataOnDeposit(data?.onDeposit || '');
      setCasinoDataSmallTerms(data?.smallTerms || '');
      setCasinoDataGeoTerms(data?.geoTerms || '');
      setCasinoDataDisplayName(data?.displayName || '');
      setCasinoDataTrackingLink(data?.trackingLink || '');
      setCasinoDataNoDepositFreeSpins(data?.noDepositFreeSpins || '');
      setCasinoDataBonusSpins(data?.bonusSpins || '');
      setCasinoDataBonusSpinCount(data?.bonusSpinCount || 0);
      setCasinoDataHasBonusCode(data?.hasBonusCode || '');
    
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error fetching casino: ", error);
    // Handle the error appropriately
  }
  console.log("IMAGE: "+ image);
  hideLoader();
};



  useEffect(() => {
    fetchSingleCasinoData();
  }, []);

//Link funcitons
const confrimLinkText = () => {
  let finalText = casinoDataTermsItems.map(item => {
    if (item.type === 'text') {
      return item.content;
    } else if (item.type === 'link') {
      return `<a href="${item.url}">${item.content}</a>`;
    }
    return '';
  }).join(' ');
  setCasinoDataTermsItems([]);
  setCasinoDataFinalTerms(finalText);
  console.log(finalText); // For demonstration
};


const addTextItem = () => {
  setCasinoDataTermsItems([...casinoDataTermsItems, { type: 'text', content: '' }]);
};

const addLinkItem = () => {
  setCasinoDataTermsItems([...casinoDataTermsItems, { type: 'link', content: '', url: '' }]);
};

const removeItem = (index: number) => {
  const newItems = [...casinoDataTermsItems];
  newItems.splice(index, 1);
  setCasinoDataTermsItems(newItems);
};

const updateItemContent = (index: number, content: string) => {
  const newItems = [...casinoDataTermsItems];
  newItems[index].content = content;
  setCasinoDataTermsItems(newItems);
};

const updateLinkUrl = (index: number, url: string) => {
  const newItems = [...casinoDataTermsItems];
  newItems[index].url = url;
  setCasinoDataTermsItems(newItems);
};

const renderItem = (item: { type: 'text' | 'link', content: string, url?: string }, index: number) => {
  return (
    <div key={index}>
      {item.type === 'text' && (
        <div className='termstextlinkparent'>
        <input
        className='termstextsubmitform'
        placeholder="Significant T&C"
          value={item.content}
          onChange={(e) => updateItemContent(index, e.target.value)}
        />
        <a className='termsbuttonstyle' onClick={() => removeItem(index)}>Remove</a>
        </div>
      )}
      {item.type === 'link' && (
        <div className='termstextlinkparent'>
          <input
          className='termslinktextsubmitform'
            placeholder="Text"
            value={item.content}
            onChange={(e) => updateItemContent(index, e.target.value)}
          />
          <input
          className='termslinktextsubmitform'
            placeholder="URL"
            value={item.url}
            onChange={(e) => updateLinkUrl(index, e.target.value)}
          />
          <button className='termsbuttonstyle' onClick={() => removeItem(index)}>Remove</button>
        </div>
      )}
    </div>
  );
};

//Image functions
const handleImageClick = () => {
  inputRef.current?.click();
};

const handleDragOver = (e: React.DragEvent<HTMLImageElement>) => {
  e.preventDefault();
};

const handleDrop = (e: React.DragEvent<HTMLImageElement>) => {
  e.preventDefault();
  if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    setImage(e.dataTransfer.files[0]);
  }
};

const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setImageError('');
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      if (img.width === 300 && img.height === 100) {
        setImage(file);
      } else {
        setImageError('Image must be 300x100 pixels in size.');
        // Reset to default image if needed
        setImage(image);
      }
    };
  }
};

const handleSaveChanges = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
  showLoader();
  event.preventDefault();

  if (!casinoDataFinalTerms || !casinoDataPositionNumber || !casinoDataNoDepositOffer ||
    !casinoDataDepositBonustOffer || !casinoDataBonusCode || !casinoDataOnDeposit ||
    !casinoDataSmallTerms || !casinoDataGeoTerms || !casinoDataDisplayName ||
    !casinoDataTrackingLink || !casinoDataNoDepositFreeSpins || !casinoDataBonusSpins ||
    !casinoDataHasBonusCode) {
      //Fix alert
      showNotification('Please fill in all the fields!', 'error');
      hideLoader();
    return;
  }

  if (image === '/DefaultImage.png') {
    showNotification('Please upload an Image!', 'error');
    hideLoader();
    return;
  };

  if (!auth.currentUser) {
    showNotification('User is not authenticated!', 'error');
    hideLoader();
    return;
  };

  const now = new Date();
  const day = now.getDay();
  let NewImageName:string=''
  let imageUrl = image;
  if (image instanceof File) {
    const storage = getStorage();
    const fileName = `${Date.now()}-${image.name}`; // Modified line for correct file name format
    NewImageName = fileName;
    const imageRef = ref(storage, `${siteId}/images/${day}/${geoId}/${pageId}/${fileName}`);

    try {
      const snapshot = await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(snapshot.ref);
      const OldImagePath = imageName?.split('/')[1];
      const ImageToDelete = imageName?.split('/')[0];
      const deleteCasinoImage = ref(storage, `${siteId}/images/${OldImagePath}/${geoId}/${pageId}/${ImageToDelete}`);
      await deleteObject(deleteCasinoImage).catch((err) => { console.log('nothing to delete') });
    } catch (error) {
        console.error("Error uploading image: ", error);
        showNotification('Error uploading image: ' + error, 'error');
        hideLoader();
        return;
    }
  }

  const casinosPath = `/sites/${siteId}/geos/${geoId}/pages/${pageId}/casinos`;
  const casinosRef = collection(dbCasinos, casinosPath);

  try {
    if (casinoDataPositionNumber < 1) {
      showNotification('Postion number must be higher than 1!', 'error');
      hideLoader();
      return;
    }
    const q = query(casinosRef, orderBy("positionNumber"));

    await runTransaction(dbCasinos, async (transaction) => {
      const querySnapshot = await getDocs(q);
      const affectedCasinos = querySnapshot.docs.map(doc =>
      ({
        ref: doc.ref,
        data: doc.data() as ICasino
      }));

      unassignedCasinoMaxCheck = false;
      const LastPosNr = affectedCasinos[affectedCasinos.length - 1].data.positionNumber;

      if (casinoDataPositionNumber > LastPosNr) {
        More_than_Max = true;
      } else {
        More_than_Max = false;
      }

      if (((InitialPosNr === 1) && (casinoDataPositionNumber >= LastPosNr))) {
        PosNr_More_Boolean = true;
      } else {
        PosNr_More_Boolean = false;
      };

      if ((InitialPosNr === LastPosNr) && (casinoDataPositionNumber === 1)) {
        PosNr_Less_Boolean = true;
      } else {
        PosNr_Less_Boolean = false;
      };

      for (const casino of affectedCasinos) {
        const casinoData = casino.data;

        if (!(InitialPosNr <= 0)) {
          if (PosNr_More_Boolean && !(casinoData.positionNumber < 1)) {
            const newPosition = casinoData.positionNumber - 1;
            transaction.update(casino.ref, { positionNumber: newPosition });
          } else if (((PosNr_More_Boolean === false) && (casinoData.positionNumber > InitialPosNr) && (casinoData.positionNumber <= casinoDataPositionNumber) && (casinoDataPositionNumber > InitialPosNr))
            || ((PosNr_More_Boolean === false) && (casinoData.positionNumber > InitialPosNr) && (casinoDataPositionNumber >= LastPosNr))
          ) {
            const newPosition = casinoData.positionNumber - 1;
            transaction.update(casino.ref, { positionNumber: newPosition });
          } else if (PosNr_Less_Boolean && !(casinoData.positionNumber < 1)) {
            const newPosition = casinoData.positionNumber + 1;
            transaction.update(casino.ref, { positionNumber: newPosition });
          } else if ((!(PosNr_Less_Boolean)) && (casinoData.positionNumber < InitialPosNr) && (casinoData.positionNumber >= casinoDataPositionNumber)) {
            const newPosition = casinoData.positionNumber + 1;
            transaction.update(casino.ref, { positionNumber: newPosition });
          };
        } else {
          if (casinoData.positionNumber >= casinoDataPositionNumber) {
            const newPosition = casinoData.positionNumber + 1;
            transaction.update(casino.ref, { positionNumber: newPosition });
          } else if (More_than_Max) {
            More_than_Max = false;
            unassignedCasinoMaxCheck = true;
          }
        }
      }

      const newCasinoData = {
        image: imageUrl,
        finalTerms: casinoDataFinalTerms,
        positionNumber: More_than_Max ? LastPosNr : unassignedCasinoMaxCheck ? (LastPosNr + 1) : casinoDataPositionNumber,
        noDepositOffer: casinoDataNoDepositOffer,
        depositBonusOffer: casinoDataDepositBonustOffer,
        bonusCode: casinoDataBonusCode,
        onDeposit: casinoDataOnDeposit,
        smallTerms: casinoDataSmallTerms,
        geoTerms: casinoDataGeoTerms,
        displayName: casinoDataDisplayName,
        trackingLink: casinoDataTrackingLink,
        PluginLink: `https://nomble.app/console/go/${siteId}/${geoId}/${pageId}/${casinoId}`,
        noDepositFreeSpins: casinoDataNoDepositFreeSpins,
        bonusSpins: casinoDataBonusSpins,
        bonusSpinCount: casinoDataBonusSpinCount,
        hasBonusCode: casinoDataHasBonusCode,
        CasinoPicName: NewImageName ? `${NewImageName}` : imageName
      };

      const docRef = doc(dbCasinos, casinosPath, casinoId);
      transaction.update(docRef, newCasinoData);
    });

    console.log("Casino data updated and positions adjusted");
    navigate(`/console/casinos?site=${siteId}&geo=${geoId}&page=${pageId}`);
  } catch (e) {
    console.error("Error in transaction: ", e);
    showNotification('Something went wrong: ' + e, 'error');
  }
  hideLoader();
};



useEffect(() => {
  let unsubscribeFunc: Unsubscribe | null = null;
  let isInitialLoad = true;  // Flag to track the initial load

  if (pageId) {
      const casinosPath = `/sites/${siteId}/geos/${geoId}/pages/${pageId}/casinos`;
      const q = query(collection(dbCasinos, casinosPath));

      unsubscribeFunc = onSnapshot(q, (snapshot) => {
          if (isInitialLoad) {
              isInitialLoad = false;  // After the first load, set the flag to false
              return;  // Skip the rest of the function during the initial load
          }
        
          // Handle actual data changes here
          console.log('Data changed');
          setShowMessage(true);
      });
      return () => {
        if (unsubscribeFunc) {
            unsubscribeFunc();
        }
    };
  }

}, [pageId]);
  
useEffect(() => {
  let unsubscribeFunc: Unsubscribe | null = null;
  let isInitialLoad = true; // Flag to track the initial load

  const sitesPath = `/sites/`;
  const q = query(collection(dbCasinos, sitesPath));

  unsubscribeFunc = onSnapshot(q, (snapshot) => {
      if (isInitialLoad) {
          isInitialLoad = false; // After the first load, set the flag to false
          return; // Skip the rest of the function during the initial load
      }

      // Handle actual data changes here
      console.log('check');
      setShowSitesChangedMessage(true);
  });

  // Cleanup function
  return () => {
      if (unsubscribeFunc) {
          unsubscribeFunc();
      }
  };
}, []); // Dependency array is empty to run only once on component mount

  
return(

  <>
  <div className='dashpanel'>
  <div className="main">
  {showMessage && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>Someone made a change to your current page!</b></p>
      <button className='sitemanagerbutton selected' onClick={() => hideMessage()}>REFRESH PAGE</button>
    </div>
  </div>
)}
{showSitesChangedMessage && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>A GEO/Page has been created/deleted!</b></p>
      <button className='sitemanagerbutton selected' onClick={() => hideSitesChangedMessage()}>REFRESH PAGE</button>
    </div>
  </div>
)}
        <div className="head">
        <h1>Modify <span className="usertext">Casino</span></h1> 
          <h2>Change the details below to modify the casino</h2>
          <p>You can click the image and/or drag and drop to change it.</p>
  <hr></hr>
          <br></br>
          <form className="new-booking-form" onSubmit={handleSaveChanges}>
  <div className="cas-cont-wrapper cas-cont-box-100">
<div className="cas-cont-wrapper-child cas-cont-box-100">
<div className="cas-cont cas-cont-box-100">
<div className="cas-left cas-cont-box-25">
<div className="cas-img-cont-wrap cas-inner-pd cas-relative">
<div className="cas-left-cont cas-cont-box-100">
  <div className="cas-img-container cas-relative">
    <div className="cas-pos-number">
    <input type="number" value={casinoDataPositionNumber} onChange={(e) => setCasinoDataPositionNumber(parseInt(e.target.value, 10) || 0)} placeholder="#" className="numbersubmitform" />
    </div>
    {imageError && <p style={{ color: 'red' }}>{imageError}</p>}

{/* Optionally display the selected image */}
<input
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={handleImageChange}
      ref={inputRef} // Reference to this input
    />

    <img
      className='cas-img'
      src={typeof image === 'string' ? image : URL.createObjectURL(image)}
      alt="Selected"
      style={{ maxWidth: '150px', maxHeight: '100px' }}
      onClick={() => inputRef.current && inputRef.current.click()} // Trigger file input on click
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    />
  </div>
  <div className="cas-accepted-txt cas-relative">
    <div className="cas-acc-text cas-cont-box-100 cas-relative">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
<g fill="#4dd82d" stroke="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g>
</svg> 
<input value={casinoDataGeoTerms} onChange={(e) => setCasinoDataGeoTerms(e.target.value)}  placeholder='ex: UK Players Accept...' className='submitform'></input>
    </div>
  </div>
</div>
</div>
</div>
<div className="cas-center cas-relative cas-cont-box-50">
<div className="cas-inner-pd cas-relative cas-cont-box-50">
<div className="cas-center-items cas-relative cas-cont-box-100">
  <p className="cas-bonus-text">No Deposit Offer</p>
  <p className="cas-bonus-text"><input value={casinoDataNoDepositOffer} onChange={(e) => setCasinoDataNoDepositOffer(e.target.value)}  placeholder='ex: 10 Free Spins' className='submitform'></input></p>
  <p className="cas-terms-text">Bonus Code:<input onChange={(e) => setCasinoDataBonusCode(e.target.value)} disabled={!casinoDataBonusCodeAvailable} value={casinoDataBonusCode} placeholder='ex: BETTER50' className='submitform'></input></p>
</div>
</div>
<div className="cas-inner-pd cas-relative cas-cont-box-50">
<div className="cas-center-items cas-relative cas-cont-box-100">
  <p className="cas-bonus-text">Deposit Bonus Offer</p>
  <p className="cas-bonus-text"><input value={casinoDataDepositBonustOffer} onChange={(e) => setCasinoDataDepositBonustOffer(e.target.value)}  placeholder='ex: 100% up to €700' className='submitform'></input></p>
  <p className="cas-terms-text">On Deposit:<input value={casinoDataOnDeposit}  onChange={(e) => setCasinoDataOnDeposit(e.target.value)} placeholder='ex: 500 Bonus Spins' className='submitform'></input></p>
</div>
</div>
</div>
<div className="cas-right cas-relative cas-cont-box-25">
<div className="cas-inner-pd-spec cas-relative cas-cont-box-100">
<div className="cas-right-items cas-relative cas-cont-box-100">
  <div className="cas-claim-button cas-relative cas-text-center">
    <div className="cas-claim-btn cas-cont-box-100 cas-relative">
    <a className="ribbon-wrapper" title="CLAIM BONUS" ><div className="glow"> &nbsp;</div> <span>CLAIM BONUS</span></a>
      <div className="cas-accepted-txt cas-relative">
        <div className="cas-acc-text-btn cas-cont-box-100 cas-relative">
        <input value={casinoDataSmallTerms} onChange={(e) => setCasinoDataSmallTerms(e.target.value)}  placeholder='ex: 18+ New Players On...' className='submitform'></input>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
<div className="cas-terms-section cas-cont-box-100">
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256"
>
<g fill="#2DBAD8" stroke="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.703,0 -23,10.297 -23,23c0,12.703 10.297,23 23,23c12.703,0 23,-10.297 23,-23c0,-12.703 -10.297,-23 -23,-23zM25,11c1.657,0 3,1.343 3,3c0,1.657 -1.343,3 -3,3c-1.657,0 -3,-1.343 -3,-3c0,-1.657 1.343,-3 3,-3zM29,38h-2h-4h-2v-2h2v-13h-2v-2h2h4v2v13h2z"></path></g></g>
</svg>
<span className="cas-terms-section-text cas-cont-box-100">
{casinoDataFinalTerms}
{casinoDataTermsItems.map(renderItem)}
<div style={{paddingTop: '10px',paddingBottom: '10px'}}>
<a className='termsbuttonstyleadd' onClick={addTextItem}>Add Significant T&C</a>
<a className='termsbuttonstyleadd' onClick={addLinkItem}>Add T&C Website</a> 
<a className='termsbuttonstyle selected' onClick={confrimLinkText}>Confirm</a> 
</div>
</span>
</div>
</div>
</div>
<table className="cas-bonus-table">
<thead>
<tr>
<th style={{textAlign: 'left'}} scope="col">

</th>
<th style={{textAlign: 'left'}} scope="col">
Details to Input
</th>
</tr>
</thead>
<tbody>
<tr>
<th style={{textAlign: 'left'}} title="Casino Name" scope="row">
Display Name
</th>
<td style={{textAlign: 'left'}}>
<input value={casinoDataDisplayName} onChange={(e) => setCasinoDataDisplayName(e.target.value)}  placeholder='ex: Casigo' className='submitform'></input>
</td>
</tr>
<tr>
<th style={{textAlign: 'left'}} title="Tracking Link to the casino"
scope="row">
Tracking Link
</th>
<td style={{textAlign: 'left'}}>
<input value={casinoDataTrackingLink} onChange={(e) => setCasinoDataTrackingLink(e.target.value)}  placeholder='ex: https://tracker.com/' className='submitform'></input>
</td>
</tr>
<tr>
<th style={{textAlign: 'left'}} title="No deposit free spins check" scope="row">
No Deposit Free Spins
</th>
<td style={{textAlign: 'left'}}>
<input

style={{marginRight: '2px'}}
type="radio"
value="Yes"
name="radioGroup"
id="radioGroup1item1"
checked={casinoDataNoDepositFreeSpins === 'Yes'}
onChange={(e) => setCasinoDataNoDepositFreeSpins(e.target.value)}
/>
<label style={{marginRight: '15px'}} htmlFor="radioGroup1item1">Yes</label>

<input

style={{marginRight: '2px'}}
type="radio"
value="No"
name="radioGroup"
id="radioGroup1item2"
checked={casinoDataNoDepositFreeSpins === 'No'}
onChange={(e) => setCasinoDataNoDepositFreeSpins(e.target.value)}
/>
<label htmlFor="radioGroup1item2">No</label>
</td>
</tr>
<tr>
<th style={{textAlign: 'left'}} title="Bonus Spins Check" scope="row">
Bonus Spins
</th>
<td style={{textAlign: 'left'}}>
<input
style={{marginRight: '2px'}}
type="radio"
value="Yes"
name="radioGroup2"
id="radioGroup2item1"
checked={casinoDataBonusSpins === 'Yes'}
onChange={(e) => setCasinoDataBonusSpins(e.target.value)}
/>
<label style={{marginRight: '15px'}} htmlFor="radioGroup2item1">Yes</label>

<input

style={{marginRight: '2px'}}
type="radio"
value="No"
name="radioGroup2"
id="radioGroup2item2"
checked={casinoDataBonusSpins === 'No'}
onChange={(e) => setCasinoDataBonusSpins(e.target.value)}
/>
<label htmlFor="radioGroup2item2">No</label>
</td>
</tr>
{casinoDataBonusSpins === 'Yes' && (
<tr>
<th style={{textAlign: 'left'}} title="Amount of Bonus Spins" scope="row">
Bonus Spins Count:
</th>
<td style={{textAlign: 'left'}}>
<input value={casinoDataBonusSpinCount} onChange={(e) => setCasinoDataBonusSpinCount(parseInt(e.target.value, 10) || 0)}  placeholder='ex: 200' type={'number'} className='submitform'></input>
</td>
</tr>
)}
    <tr>
      <th style={{textAlign: 'left'}} title="Bonus code check" scope="row">
        Bonus Code Available
      </th>
      <td style={{textAlign: 'left'}}>
      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="Yes"
        name="radioGroup3"
        id="radioGroup3item1"
        checked={casinoDataHasBonusCode === 'Yes'}
        onChange={(e) => setFixBonusCodeForCasinoDataHasBonusCode(e.target.value)}
      />
      <label style={{marginRight: '15px'}} htmlFor="radioGroup3item1">Yes</label>

      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="No"
        name="radioGroup3"
        id="radioGroup3item2"
        checked={casinoDataHasBonusCode === 'No'}
        onChange={(e) => setFixBonusCodeForCasinoDataHasBonusCode(e.target.value)}
      />
      <label htmlFor="radioGroup3item2">No</label>
      </td>
    </tr>
</tbody>
</table>
<button className='termsbuttonstyleadd'>
SAVE CASINO
</button>
<button style={{ backgroundColor: 'purple', color: 'white', fontWeight:'bolder',  marginTop: '5px', width: '120px', padding: '15px' }} onClick={() => navigate(`/console/casinos/?site=${siteId}&geo=${geoId}&page=${pageId}&nofetch=${true}`)}>Back to Casinos</button>{/*&nofetch=${true}*/}
</form>
</div>
</div>
</div>
</>



);
}  

export default ModifyCasino;
