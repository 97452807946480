// config.ts

// Ensure the environment variable is defined
if (!process.env.REACT_APP_NOMBLE_FIREBASE_CONFIG) {
  throw new Error("REACT_APP_NOMBLE_FIREBASE_CONFIG environment variable is not set");
}

// Parse the JSON string from the environment variable
const firebaseConfig: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
} = JSON.parse(process.env.REACT_APP_NOMBLE_FIREBASE_CONFIG);

export const config = {
  firebaseConfig
};
