import React, { useState, useEffect, useContext, useRef } from 'react';

import { Unsubscribe, collection, addDoc, doc, setDoc, getDocs, deleteDoc, onSnapshot, runTransaction, where, query, Firestore, orderBy } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FirebaseStorage } from 'firebase/storage';
import { v4 } from 'uuid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../universal/context/UserContext';

import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';
import {dbCasinos} from '../../App';


interface ISite {
  siteId: string;
  siteName: string;
  geos: IGeo[];
}
type ItemType = 'site' | 'geo' | 'page';
interface IGeo {
    geoId: string;
    geoDetails: string;
    pages: IPage[];
  }

interface IPage {
    pageId: string;
    pageName: string;
  }
  interface ICasino{
            image: string;
            finalTerms: string;
            positionNumber: number;
            noDepositOffer: string;
            depositBonusOffer: string;
            bonusCode: string;
            onDeposit: string;
            smallTerms: string;
            geoTerms: string;
            displayName: string;
            trackingLink: string;
            PluginLink:string,
            noDepositFreeSpins: string;
            bonusSpins: string;
            bonusSpinCount: number;
            hasBonusCode: string;
   // CasinoID:any
  }
const NewCasinoPage = () => {

  const { showLoader, hideLoader } = useLoader();
  const { showNotification } = useNotification();

  //Items to Save to DB
  //Image
  const [image, setImage] = useState<File | string>('/media/DefaultImage.png');
  //Final Terms
  const [casinoDataFinalTerms, setCasinoDataFinalTerms] = useState<string>('');
  //Position Number
  const [casinoDataPositionNumber, setCasinoDataPositionNumber] = useState<number>(0);
  //No Deposit Offer
  const [casinoDataNoDepositOffer, setCasinoDataNoDepositOffer] = useState<string>('');
  //Deposit Bonus Offer
  const [casinoDataDepositBonustOffer, setCasinoDataDepositBonustOffer] = useState<string>('');
  //Bonus Code
  const [casinoDataBonusCode, setCasinoDataBonusCode] = useState<string>('');
  //On Deposit
  const [casinoDataOnDeposit, setCasinoDataOnDeposit] = useState<string>('');
  //Small Terms
  const [casinoDataSmallTerms, setCasinoDataSmallTerms] = useState<string>('');
  //GEO Terms
  const [casinoDataGeoTerms, setCasinoDataGeoTerms] = useState<string>('');
  //Display Name
  const [casinoDataDisplayName, setCasinoDataDisplayName] = useState<string>('');
  //Tracking Link
  const [casinoDataTrackingLink, setCasinoDataTrackingLink] = useState<string>('');
  //No Deposit Free Spins
  const [casinoDataNoDepositFreeSpins, setCasinoDataNoDepositFreeSpins] = useState<string>('Yes');
  //Bonus Spins
  const [casinoDataBonusSpins, setCasinoDataBonusSpins] = useState<string>('Yes');
  //Bonus Spin Count
  const [casinoDataBonusSpinCount, setCasinoDataBonusSpinCount] = useState<number>(0);
  //Has Bonus Code
  const [casinoDataHasBonusCode, setCasinoDataHasBonusCode] = useState<string>('Yes');
    //Bonus Code Allowed
    const [casinoDataBonusCodeAvailable, setCasinoDataBonusCodeAvailable] = useState<boolean>(true);
  //Cached bonus code
  const [casinoDataCachedBonusCode, setCasinoDataCachedBonusCode] = useState<string>('');


  // const testId = () => {
  //   console.log(v4());
  // }


 //Intial Calls
 useEffect(() => {
   //Check auths
   if (!user?.allowCasinoEditing) {
     navigate('/');
   }
 });

  
  const inputRef = useRef<HTMLInputElement>(null);

  
  
  const [casinoDataTermsItems, setCasinoDataTermsItems] = useState<Array<{ type: 'text' | 'link', content: string, url?: string}>>([]);
  
  const [showSitesChangedMessage, setShowSitesChangedMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  
  const hideSitesChangedMessage = () => {
    setShowSitesChangedMessage(false);
    clearAllSelected();
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && showMessage) {
      hideMessage();
    }
  };
  
  const hideMessage = () => {
    setShowMessage(false);
  };
  
  
  useEffect(() => {
    if (showMessage) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
  
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showMessage]);
  

  const [imageError, setImageError] = useState('');


  const { user, setUser } = useContext(UserContext);
  const [sites, setSites] = useState<ISite[]>([]);
  const [name, setName] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [selectedGeoId, setSelectedGeoId] = useState<string | null>(null);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [unsubscribe, setUnsubscribe] = useState<(() => void) | null>(null);


  const confrimLinkText = () => {
    let finalText = casinoDataTermsItems.map(item => {
      if (item.type === 'text') {
        return item.content;
      } else if (item.type === 'link') {
        return `<a href="${item.url}">${item.content}</a>`;
      }
      return '';
    }).join(' ');
    setCasinoDataTermsItems([]);
    setCasinoDataFinalTerms(finalText);
    console.log(finalText); // For demonstration
  };
  
  const setFixBonusCodeForCasinoDataHasBonusCode = (state: string) => {
    if (state === 'No'){
      setCasinoDataCachedBonusCode(casinoDataBonusCode);
      setCasinoDataBonusCode('N/A');
      setCasinoDataBonusCodeAvailable(false);
    }
    else if (state === 'Yes'){
      setCasinoDataBonusCode(casinoDataCachedBonusCode);
      setCasinoDataBonusCodeAvailable(true);
      setCasinoDataCachedBonusCode('');
    }
    setCasinoDataHasBonusCode(state);
  }

  const addTextItem = () => {
    setCasinoDataTermsItems([...casinoDataTermsItems, { type: 'text', content: '' }]);
  };
  
  const addLinkItem = () => {
    setCasinoDataTermsItems([...casinoDataTermsItems, { type: 'link', content: '', url: '' }]);
  };
  
  const removeItem = (index: number) => {
    const newItems = [...casinoDataTermsItems];
    newItems.splice(index, 1);
    setCasinoDataTermsItems(newItems);
  };
  
  const updateItemContent = (index: number, content: string) => {
    const newItems = [...casinoDataTermsItems];
    newItems[index].content = content;
    setCasinoDataTermsItems(newItems);
  };
  
  const updateLinkUrl = (index: number, url: string) => {
    const newItems = [...casinoDataTermsItems];
    newItems[index].url = url;
    setCasinoDataTermsItems(newItems);
  };

  const renderItem = (item: { type: 'text' | 'link', content: string, url?: string }, index: number) => {
    return (
      <div key={index}>
        {item.type === 'text' && (
          <div className='termstextlinkparent'>
          <input
          className='termstextsubmitform'
          placeholder="Significant T&C"
            value={item.content}
            onChange={(e) => updateItemContent(index, e.target.value)}
          />
          <a className='termsbuttonstyle' onClick={() => removeItem(index)}>Remove</a>
          </div>
        )}
        {item.type === 'link' && (
          <div className='termstextlinkparent'>
            <input
            className='termslinktextsubmitform'
              placeholder="Text"
              value={item.content}
              onChange={(e) => updateItemContent(index, e.target.value)}
            />
            <input
            className='termslinktextsubmitform'
              placeholder="URL"
              value={item.url}
              onChange={(e) => updateLinkUrl(index, e.target.value)}
            />
            <button className='termsbuttonstyle' onClick={() => removeItem(index)}>Remove</button>
          </div>
        )}
      </div>
    );
  };

  const navigate = useNavigate();
  const database = dbCasinos;
  const auth = getAuth();

  const closeAllForms = () => {
    //Handle Closing forms here
  };
  const setNewSelectedSite = async (siteId: string) => {
    setSelectedGeoId(null);
    closeAllForms();
    setSelectedSiteId(siteId);
    setSelectedPageId(null);
  }
  const clearAllSelected = async () =>{
    setSelectedPageId(null);
    setSelectedGeoId(null);
    setSelectedSiteId(null);
    fetchSites();
  }
  const setNewSelectedGeo = async (geoId: string) => {
    setSelectedGeoId(geoId);
    closeAllForms();
    setSelectedPageId(null);
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageError('');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);
  
      img.onload = () => {
        if (img.width === 300 && img.height === 100) {
          setImage(file);
        } else {
          setImageError('Image must be 300x100 pixels in size.');
          // Reset to default image if needed
          setImage('/DefaultImage.png');
        }
      };
    }
  };
  
  const handleImageClick = () => {
    inputRef.current?.click();
  };
  
  const handleDragOver = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
  };
  
  const handleDrop = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImage(e.dataTransfer.files[0]);
    }
  };
  
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setErrorMessage('');
  
    if (!casinoDataFinalTerms || !casinoDataPositionNumber || !casinoDataNoDepositOffer ||
      !casinoDataDepositBonustOffer || !casinoDataBonusCode || !casinoDataOnDeposit ||
      !casinoDataSmallTerms || !casinoDataGeoTerms || !casinoDataDisplayName ||
      !casinoDataTrackingLink || !casinoDataNoDepositFreeSpins || !casinoDataBonusSpins ||
      !casinoDataHasBonusCode) {
        //Fix alert
        showNotification('Please fill in all the fields!', 'error');
      return;
    }
  
    if (image === '/media/DefaultImage.png') {
        showNotification('Please upload an image!', 'error');
        return;
    };
  
    if (!auth.currentUser) {
        showNotification('User is not authenticated!', 'error');
        return;
    };
  
    const now = new Date();
    const day = now.getDay();
    let ImageName:string=''
    let imageUrl = image;
    if (image instanceof File) {
      
        const storage = getStorage();
      const fileName = `${Date.now()}-${image.name}`; // Modified line for correct file name format
      ImageName = fileName;
      
      const imageRef = ref(storage, `${selectedSiteId}/images/${selectedGeoId}/${selectedPageId}/${fileName}`); // latest working img path
      //const imageRef = ref(storage, `${selectedSiteId}/images/${day}/${selectedGeoId}/${selectedPageId}/${fileName}`); // dev test image path
      //const casinosPath = `/sites/${selectedSiteId}/geos/${selectedGeoId}/pages/${selectedPageId}/casinos`;
  
        try {
            const snapshot = await uploadBytes(imageRef, image);
            imageUrl = await getDownloadURL(snapshot.ref);
        } catch (error) {
            showNotification('Error uploading image: ' + error, 'error');
            return;
        }
    }
  
    const casinosPath = `/sites/${selectedSiteId}/geos/${selectedGeoId}/pages/${selectedPageId}/casinos`;
    const casinosRef = collection(dbCasinos, casinosPath);
  
    try {
      // Fetch casinos with position numbers greater than or equal to the new casino's position
      const q = query(casinosRef, orderBy('positionNumber') /*where("positionNumber", ">=", casinoDataPositionNumber)*/);
      const querySnapshot = await getDocs(q);
  
      
      const affectedCasinos = querySnapshot.docs.map(doc => ({
        ref:  doc.ref,
        data: doc.data() as ICasino
      }));
  
      const casinoId = v4();
      if (affectedCasinos.length) {
      
        const lastsPos = affectedCasinos[affectedCasinos.length - 1].data.positionNumber;
        const ComparisonPosNR = (casinoDataPositionNumber > lastsPos);
      
  
        await runTransaction(dbCasinos, async (transaction) => {
        
          for (const casino of affectedCasinos) {
            const casinoData = casino.data;
              
            // Increment position number if it's the same or has already been seen
            if (casinoData.positionNumber >= casinoDataPositionNumber /*&& !ComparisonPosNR*/ /* || seenPositions.has(casinoData.positionNumber)*/) {
              const newPosition = casinoData.positionNumber + 1;
              transaction.update(casino.ref, { positionNumber: newPosition });
                
            }
          };
  
  
          // Construct new casino data
          const newCasinoData = {
            image: imageUrl,
            CasinoID: casinoId,
            finalTerms: casinoDataFinalTerms,
            positionNumber: ComparisonPosNR ? (lastsPos + 1) : casinoDataPositionNumber,
            noDepositOffer: casinoDataNoDepositOffer,
            depositBonusOffer: casinoDataDepositBonustOffer,
            bonusCode: casinoDataBonusCode,
            onDeposit: casinoDataOnDeposit,
            smallTerms: casinoDataSmallTerms,
            geoTerms: casinoDataGeoTerms,
            displayName: casinoDataDisplayName,
            trackingLink: casinoDataTrackingLink,
            PluginLink: `https://nomble.app/console/go/${selectedSiteId}/${selectedGeoId}/${selectedPageId}/${casinoId}`,
            noDepositFreeSpins: casinoDataNoDepositFreeSpins,
            bonusSpins: casinoDataBonusSpins,
            bonusSpinCount: casinoDataBonusSpinCount,
            hasBonusCode: casinoDataHasBonusCode,
            CasinoPicName: `${ImageName}/${day}`
          };
  
          // Add the new casino
          const newCasinoRef = doc(casinosRef);
          transaction.set(newCasinoRef, newCasinoData);
        });
      } else {
  
        
  
        const newCasinoData = {  
          image: imageUrl,
          CasinoID: casinoId,
          finalTerms: casinoDataFinalTerms,
          positionNumber: casinoDataPositionNumber,
          noDepositOffer: casinoDataNoDepositOffer,
          depositBonusOffer: casinoDataDepositBonustOffer,
          bonusCode: casinoDataBonusCode,
          onDeposit: casinoDataOnDeposit,
          smallTerms: casinoDataSmallTerms,
          geoTerms: casinoDataGeoTerms,
          displayName: casinoDataDisplayName,
          trackingLink: casinoDataTrackingLink, 
          PluginLink: `https://nomble.app/console/go/${selectedSiteId}/${selectedGeoId}/${selectedPageId}/${casinoId}`,
          noDepositFreeSpins: casinoDataNoDepositFreeSpins,
          bonusSpins: casinoDataBonusSpins,
          bonusSpinCount: casinoDataBonusSpinCount,
          hasBonusCode: casinoDataHasBonusCode,
          CasinoPicName: `${ImageName}/${day}`
        };//
  
        const FirstCasino = (async () => {
          await addDoc(casinosRef, newCasinoData)
        });
        FirstCasino();
      };
        showNotification('New Casino Added!', 'success');
        console.log("New casino added and positions updated");
        navigate(`/console/casinos?site=${selectedSiteId}&geo=${selectedGeoId}&page=${selectedPageId}`)
    } catch (e) {
      showNotification('An error occured: ' + e, 'error');
    }
  };
  

  


  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    showLoader();
    try {
      const sitesCollection = collection(dbCasinos, 'sites');
      const sitesSnapshot = await getDocs(sitesCollection);
      const sitesList: ISite[] = await Promise.all(sitesSnapshot.docs.map(async (siteDoc) => {
        const siteData = siteDoc.data();
        const geosCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos`);
        const geosSnapshot = await getDocs(geosCollection);
        const geos: IGeo[] = await Promise.all(geosSnapshot.docs.map(async (geoDoc) => {
          const geoData = geoDoc.data();
          const pagesCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos/${geoDoc.id}/pages`);
          const pagesSnapshot = await getDocs(pagesCollection);
          const pages: IPage[] = pagesSnapshot.docs.map(pageDoc => {
            const pageData = pageDoc.data();
            return {
              pageId: pageDoc.id,
              pageName: pageData.pageName, // Ensure this field exists in Firestore
              // Add other fields from IPage if they exist
            };
          });
          return {
            geoId: geoDoc.id,
            geoDetails: geoData.geoDetails, // Ensure this field exists in Firestore
            pages
            // Add other fields from IGeo if they exist
          };
        }));
        return {
          siteId: siteDoc.id,
          siteName: siteData.siteName, // Ensure this field exists in Firestore
          geos
        };
        
      }));
      setSites(sitesList);
    } catch (error) {
      console.error("Error fetching sites: ", error);
      alert('Error fetching sites. Please try again.');
    }
    hideLoader();
  };


  useEffect(() => {
    let unsubscribeFunc: Unsubscribe | null = null;
    let isInitialLoad = true; // Flag to track the initial load
  
    const sitesPath = `/sites/`;
    const q = query(collection(dbCasinos, sitesPath));
  
    unsubscribeFunc = onSnapshot(q, (snapshot) => {
        if (isInitialLoad) {
            isInitialLoad = false; // After the first load, set the flag to false
            return; // Skip the rest of the function during the initial load
        }
  
        // Handle actual data changes here
        console.log('check');
        setShowSitesChangedMessage(true);
    });
  
    // Cleanup function
    return () => {
        if (unsubscribeFunc) {
            unsubscribeFunc();
        }
    };
  }, []); // Dependency array is empty to run only once on component mount

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser){
        setUser(null);
      }
    });
      
  
    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);
  
  useEffect(() => {
      if (user?.role === 'admin'){
        navigate('/adminapp');
      }
    }, [user]);

  return (
    <div className='dashpanel'>
    <div className="main">
        {showSitesChangedMessage && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>A GEO/Page has been created/deleted!</b></p>
      <button className='sitemanagerbutton selected' onClick={() => hideSitesChangedMessage()}>REFRESH PAGE</button>
    </div>
  </div>
)}
        <div className="head">
          <h1>New Casino</h1>
          <h2>Select a Site, GEO and Page to create a new Casino</h2>
              <div className="site-buttons-container">
                {sites.map((site) => (
                  <button 
                    key={site.siteId} 
                    onClick={() => setNewSelectedSite(site.siteId)}
                    className={selectedSiteId === site.siteId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                  >
                    {site.siteName}
                  </button>
                ))}
              </div>
    
              {sites.filter(site => site.siteId === selectedSiteId).map((site) => (
                <React.Fragment key={site.siteId}> {/* Key added here */}
                  <div className="geo-buttons-container">
                    <hr></hr>
                    {site.geos.length > 0 ? (
                      site.geos.map((geo) => (
                        <button 
                          key={geo.geoId} // Unique key for each geo button
                          onClick={() => setNewSelectedGeo(geo.geoId)}
                          className={selectedGeoId === geo.geoId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                        >
                          {geo.geoDetails}
                        </button>
                      ))
                    ) : (
                      <p>No GEOs found for this site.</p>
                    )}
                  </div>
    
                  <div className="page-list-container">
                  <hr></hr>
                    {site.geos.map((geo) => (
                      <div key={site.siteId + '-' + geo.geoId} className="geo-page-list-container"> {/* Unique key for each geo div */}
                        {selectedGeoId === geo.geoId && (
                          geo.pages.length > 0 ? (
                            geo.pages.map((page) => (
                              <button 
                                key={page.pageId} // Unique key for each page button
                                onClick={() => setSelectedPageId(page.pageId)}
                                className={selectedPageId === page.pageId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                              >
                                {page.pageName}
                              </button>
                            ))
                          ) : (
                            <p>No pages found for this GEO.</p>
                          )
                        )}
                      </div>
                    ))}
                  </div>  
                </React.Fragment>
              ))}
    {selectedSiteId === 'ADLER' && selectedPageId && (<>ADLER in Development!</>)}
          {/* {selectedSiteId === 'ZMS' && selectedPageId && (<>Zamsino in Development!</>)} */}
          {/*{selectedSiteId === 'BB' && selectedPageId && (<>BetterBonus</>)}*/}
          {(selectedSiteId === 'BB' /*|| selectedSiteId === 'TEST'*/ )  && selectedPageId &&(
            <>
            <hr></hr>
                    <br></br>
                    <form className="new-booking-form" onSubmit={handleSubmit}>
            <div className="cas-cont-wrapper cas-cont-box-100">
  <div className="cas-cont-wrapper-child cas-cont-box-100">
    <div className="cas-cont cas-cont-box-100">
      <div className="cas-left cas-cont-box-25">
        <div className="cas-img-cont-wrap cas-inner-pd cas-relative">
          <div className="cas-left-cont cas-cont-box-100">
            <div className="cas-img-container cas-relative">
              <div className="cas-pos-number">
              <input onChange={(e) => setCasinoDataPositionNumber(parseInt(e.target.value, 10 || 0 ))} type="number" placeholder='#' className='numbersubmitform'></input>
              </div>
              {imageError && <p style={{ color: 'red' }}>{imageError}</p>}

    {/* Optionally display the selected image */}
    <input
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={handleImageChange}
      ref={inputRef} // Reference to this input
    />

    <img
      className='cas-img'
      src={typeof image === 'string' ? image : URL.createObjectURL(image)}
      alt="Selected"
      style={{ maxWidth: '150px', maxHeight: '100px' }}
      onClick={() => inputRef.current && inputRef.current.click()} // Trigger file input on click
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    />
                {/*<image height="50px" width="150px" {{"https://betterbonus.com/wp-content/uploads/CazinoStars_Casino_Logo_NEW.jpg"}} className="cas-img" alt="cazinostars casino logo">*/}
            </div>
            <div className="cas-accepted-txt cas-relative">
              <div className="cas-acc-text cas-cont-box-100 cas-relative">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
<g fill="#4dd82d" stroke="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g>
</svg> 
<input onChange={(e) => setCasinoDataGeoTerms(e.target.value)}  placeholder='ex: UK Players Accept...' className='submitform'></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cas-center cas-relative cas-cont-box-50">
        <div className="cas-inner-pd cas-relative cas-cont-box-50">
          <div className="cas-center-items cas-relative cas-cont-box-100">
            <p className="cas-bonus-text">No Deposit Offer</p>
            <p className="cas-bonus-text"><input onChange={(e) => setCasinoDataNoDepositOffer(e.target.value)}  placeholder='ex: 10 Free Spins' className='submitform'></input></p>
            <p className="cas-terms-text">Bonus Code:<input onChange={(e) => setCasinoDataBonusCode(e.target.value)} disabled={!casinoDataBonusCodeAvailable} value={casinoDataBonusCode} placeholder='ex: BETTER50' className='submitform'></input></p>
          </div>
        </div>
        <div className="cas-inner-pd cas-relative cas-cont-box-50">
          <div className="cas-center-items cas-relative cas-cont-box-100">
            <p className="cas-bonus-text">Deposit Bonus Offer</p>
            <p className="cas-bonus-text"><input onChange={(e) => setCasinoDataDepositBonustOffer(e.target.value)}  placeholder='ex: 100% up to €700' className='submitform'></input></p>
            <p className="cas-terms-text">On Deposit:<input  onChange={(e) => setCasinoDataOnDeposit(e.target.value)} placeholder='ex: 500 Bonus Spins' className='submitform'></input></p>
          </div>
        </div>
      </div>
      <div className="cas-right cas-relative cas-cont-box-25">
        <div className="cas-inner-pd-spec cas-relative cas-cont-box-100">
          <div className="cas-right-items cas-relative cas-cont-box-100">
            <div className="cas-claim-button cas-relative cas-text-center">
              <div className="cas-claim-btn cas-cont-box-100 cas-relative">
              <a className="ribbon-wrapper" title="CLAIM BONUS" href="#"><div className="glow"> &nbsp;</div> <span>CLAIM BONUS</span></a>
                <div className="cas-accepted-txt cas-relative">
                  <div className="cas-acc-text-btn cas-cont-box-100 cas-relative">
                  <input onChange={(e) => setCasinoDataSmallTerms(e.target.value)}  placeholder='ex: 18+ New Players On...' className='submitform'></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cas-terms-section cas-cont-box-100">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256"
>
<g fill="#2DBAD8" stroke="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.703,0 -23,10.297 -23,23c0,12.703 10.297,23 23,23c12.703,0 23,-10.297 23,-23c0,-12.703 -10.297,-23 -23,-23zM25,11c1.657,0 3,1.343 3,3c0,1.657 -1.343,3 -3,3c-1.657,0 -3,-1.343 -3,-3c0,-1.657 1.343,-3 3,-3zM29,38h-2h-4h-2v-2h2v-13h-2v-2h2h4v2v13h2z"></path></g></g>
</svg>
      <span className="cas-terms-section-text cas-cont-box-100">
        {casinoDataFinalTerms}
      {casinoDataTermsItems.map(renderItem)}
      <div style={{paddingTop: '10px',paddingBottom: '10px'}}>
<a className='termsbuttonstyleadd' onClick={addTextItem}>Add Significant T&C</a>
<a className='termsbuttonstyleadd' onClick={addLinkItem}>Add T&C Website</a> 
<a className='termsbuttonstyle selected' onClick={confrimLinkText}>Confirm</a> 
</div>
      </span>
    </div>
  </div>
  </div>
  <table className="cas-bonus-table">
  <thead>
    <tr>
      <th style={{textAlign: 'left'}} scope="col">
        
      </th>
      <th style={{textAlign: 'left'}} scope="col">
        Details to Input
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{textAlign: 'left'}} title="Casino Name" scope="row">
      Display Name
      </th>
      <td style={{textAlign: 'left'}}>
      <input onChange={(e) => setCasinoDataDisplayName(e.target.value)}  placeholder='ex: Casigo' className='submitform'></input>
      </td>
    </tr>
    <tr>
      <th style={{textAlign: 'left'}} title="Tracking Link to the casino"
      scope="row">
        Tracking Link
      </th>
      <td style={{textAlign: 'left'}}>
      <input onChange={(e) => setCasinoDataTrackingLink(e.target.value)}  placeholder='ex: https://tracker.com/' className='submitform'></input>
      </td>
    </tr>
    <tr>
      <th style={{textAlign: 'left'}} title="No deposit free spins check" scope="row">
        No Deposit Free Spins
      </th>
      <td style={{textAlign: 'left'}}>
      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="Yes"
        name="radioGroup"
        id="radioGroup1item1"
        checked={casinoDataNoDepositFreeSpins === 'Yes'}
        onChange={(e) => setCasinoDataNoDepositFreeSpins(e.target.value)}
      />
      <label style={{marginRight: '15px'}} htmlFor="radioGroup1item1">Yes</label>

      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="No"
        name="radioGroup"
        id="radioGroup1item2"
        checked={casinoDataNoDepositFreeSpins === 'No'}
        onChange={(e) => setCasinoDataNoDepositFreeSpins(e.target.value)}
      />
      <label htmlFor="radioGroup1item2">No</label>
      </td>
    </tr>
    <tr>
      <th style={{textAlign: 'left'}} title="Bonus Spins Check" scope="row">
        Bonus Spins
      </th>
      <td style={{textAlign: 'left'}}>
      <input
      style={{marginRight: '2px'}}
        type="radio"
        value="Yes"
        name="radioGroup2"
        id="radioGroup2item1"
        checked={casinoDataBonusSpins === 'Yes'}
        onChange={(e) => setCasinoDataBonusSpins(e.target.value)}
      />
      <label style={{marginRight: '15px'}} htmlFor="radioGroup2item1">Yes</label>

      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="No"
        name="radioGroup2"
        id="radioGroup2item2"
        checked={casinoDataBonusSpins === 'No'}
        onChange={(e) => setCasinoDataBonusSpins(e.target.value)}
      />
      <label htmlFor="radioGroup2item2">No</label>
      </td>
    </tr>
    {casinoDataBonusSpins === 'Yes' && (
  <tr>
    <th style={{textAlign: 'left'}} title="Amount of Bonus Spins" scope="row">
      Bonus Spins Count
    </th>
    <td style={{textAlign: 'left'}}>
      <input onChange={(e) => setCasinoDataBonusSpinCount(parseInt(e.target.value, 10) || 0)}  placeholder='ex: 200' type={'number'} className='submitform'></input>
    </td>
  </tr>
)}
<tr>
      <th style={{textAlign: 'left'}} title="Bonus code check" scope="row">
        Bonus Code Available
      </th>
      <td style={{textAlign: 'left'}}>
      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="Yes"
        name="radioGroup3"
        id="radioGroup3item1"
        checked={casinoDataHasBonusCode === 'Yes'}
        onChange={(e) => setFixBonusCodeForCasinoDataHasBonusCode(e.target.value)}
      />
      <label style={{marginRight: '15px'}} htmlFor="radioGroup3item1">Yes</label>

      <input
      
      style={{marginRight: '2px'}}
        type="radio"
        value="No"
        name="radioGroup3"
        id="radioGroup3item2"
        checked={casinoDataHasBonusCode === 'No'}
        onChange={(e) => setFixBonusCodeForCasinoDataHasBonusCode(e.target.value)}
      />
      <label htmlFor="radioGroup3item2">No</label>
      </td>
    </tr>
  </tbody>
</table>
<button className='termsbuttonstyleadd'>
ADD CASINO
</button>          
</form>
</>

          )}
        </div>
      </div>
      </div>
    );
    
};

export default NewCasinoPage;
