import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../universal/context/UserContext';

const Navbar: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const auth = getAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false); // loading

  const [mainPage, setMainPage] = useState<string | null>(null);
  const [pageName, setPageName] = useState<string | null>(null);

  const [iconName, setIconName] = useState<IconName>('house');

  const iconsEnabled = user?.navbarIconsEnabled === 'true';

  const [activeIcon, setActiveIcon] = useState<'btn enter' | 'btn exit'>('btn enter');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
        navigate('/');
      }
    });

    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (user.role === 'user') {
        setVisible(true);
      } else if (user.role === 'admin') {
        setVisible(false);
        navigate('/admin');
      } else {
        setVisible(false);
      }
  
      if (user.mainPage) {
        setMainPage(user.mainPage);
      }
    } else {
      setVisible(false);
    }
  }, [user]);
  
  useEffect(() => {
    if (mainPage && mainPage !== "dashboard") {
      switch (mainPage) {
        case 'slots': {
          setPageName('Slots');
          break;
        }
        case 'hreflangs': {
          setPageName('Hreflangs');
          break;
        }
        case 'casinos': {
          setPageName('Casinos');
          break;
        }
        case 'sitelister': {
          setPageName('Site Lister');
          break;
        }
        case 'backups': {
          setPageName('Backup Manager');
          break;
        }
        default: {
          setPageName('Dashboard');
          break;
        }
      }
    } else {
      setPageName('Dashboard');
    }
  }, [mainPage]);

  useEffect(() => {
    if (mainPage && mainPage !== "dashboard") {
      switch (mainPage) {
        case 'slots': {
          setIconName('code');
          break;
        }
        case 'hreflangs': {
          setIconName('language');
          break;
        }
        case 'casinos': {
          setIconName('gamepad');
          break;
        }
        case 'sitelister': {
          setIconName('globe');
          break;
        }
        case 'backups': {
          setIconName('box');
          break;
        }
        default: {
          setIconName('house');
          break;
        }
      }
    } else {
      setIconName('house');
    }
  }, [mainPage]);
  
  

  const handleToggleSidebar = () => {
    setActiveIcon((prevIcon) => (prevIcon === 'btn enter' ? 'btn exit' : 'btn enter'));
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        handleToggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleLinkClick = () => {
    if (isOpen) {
      handleToggleSidebar(); // Close the sidebar
    }
  };

  const handleLogout = async () => {
    await auth.signOut()
      .then(() => {
        setUser(null);  // Clears the user from context and localStorage
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };


  

  if (visible) {
    return (
      <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
        <div className="logo_details">
          <img
            className="icon minimg"
            src="/logos/nomble-favicon-white.png"
          />
          <div className="logo_name">nomble</div>
          <FontAwesomeIcon className={activeIcon === 'btn enter' ? 'btn enter active-icon' : 'btn enter inactive-icon'} onClick={handleToggleSidebar} icon={['fas', 'bars']} />
          <FontAwesomeIcon className={activeIcon === 'btn exit' ? 'btn exit active-icon' : 'btn exit inactive-icon'} onClick={handleToggleSidebar} icon={['fas', 'xmark']} />
        </div>
      {user?.actionButton!=="disabled" && user?.casinoEditing!=="false" &&(
        <li className="headcta">
        <Link to='/console/new-casino' onClick={handleLinkClick}>
        <FontAwesomeIcon className='listed icon' icon={['fas', 'dice']}/>
          <span className="link_name">New Casino</span>
        </Link>
        <span className="tooltip">New Casino</span>
      </li>
)}
       
        <ul className="nav-list">

          {user?.mainPage === "dashboard" && (
          <li className={location.pathname === '/console/' ? 'activepage' : 'inactivepage'}>
            <Link to='/console/' onClick={handleLinkClick}>
              <FontAwesomeIcon className='listed icon' icon={['fas', 'house']} />
              <span className="link_name">Dashboard</span>
            </Link>
            <span className="tooltip">Dashboard</span>
          </li>
          )}

          {user?.mainPage !== "dashboard" && (
          <li className={location.pathname === '/console/' + mainPage ? 'activepage' : 'inactivepage'}>
            <Link to={'/console/' + mainPage} onClick={handleLinkClick}>
              <FontAwesomeIcon className='listed icon' icon={['fas', iconName]} />
              <span className="link_name">{pageName}</span>
            </Link>
            <span className="tooltip">{pageName}</span>
          </li>
          )}

{user?.mainPage !== "casinos" && (
          user?.allowCasinoEditing && user.casinoEditing!=="false" && (

            <li className={location.pathname === '/console/casinos' ? 'activepage' : 'inactivepage'}>
              <Link to='/console/casinos' onClick={handleLinkClick}>
                <FontAwesomeIcon className='listed icon' icon={['fas', 'gamepad']} />
                <span className="link_name">Casinos</span>
              </Link>
              <span className="tooltip">Casinos</span>
            </li>
          )
)}
            
            {user?.allowCasinoEditing && user.casinoEditing!=="false" && (
<li className={location.pathname === '/console/update' ? 'activepage' : 'inactivepage'}>
              <Link to='/console/update' onClick={handleLinkClick}>
                <FontAwesomeIcon className='listed icon' icon={['fas', 'pen-to-square']} />
                <span className="link_name">Update Casino</span>
              </Link>
              <span className="tooltip">Update Casino</span>
            </li>
            )}


{user?.mainPage !== "slots" && (
          user?.allowSlotCodeEditing && user.slotCodeEditing!=="false" && (
            <li className={location.pathname === '/console/slots' ? 'activepage' : 'inactivepage'}>
              <Link to='/console/slots' onClick={handleLinkClick}>
                <FontAwesomeIcon className='listed icon' icon={['fas', 'code']} />
                <span className="link_name">Slots</span>
              </Link>
              <span className="tooltip">Slots</span>
            </li>
          )
)}

{user?.mainPage !== "slots" && (
  /*<></>*/
  
          user?.allowSlotCodeEditing && user.slotCodeEditing!=="false" && (
            <li className={location.pathname === '/console/slot' ? 'activepage' : 'inactivepage'}>
              <Link to='/console/slot' onClick={handleLinkClick}>
                <FontAwesomeIcon className='listed icon' icon={['fas', 'dice']} />
                <span className="link_name">Slot Games</span>
              </Link>
              <span className="tooltip">Slot Games</span>
            </li>
          )
          
)}


          {user?.allowHreflangSetEditing && user.hreflangSetEditing!=="false" && (
            <>
            {user?.mainPage !== "hreflangs" && (
              <li className={location.pathname === '/console/hreflangs' ? 'activepage' : 'inactivepage'}>
                <Link to='/console/hreflangs' onClick={handleLinkClick}>
                  <FontAwesomeIcon className='listed icon' icon={['fas', 'language']} />
                  <span className="link_name">Hreflangs</span>
                </Link>
                <span className="tooltip">Hreflangs</span>
              </li>
              )}

{user?.mainPage !== "sitelister" && (
              <li className={location.pathname === '/console/sitelister' ? 'activepage' : 'inactivepage'}>
                <Link to='/console/sitelister' onClick={handleLinkClick}>
                  <FontAwesomeIcon className='listed icon' icon={['fas', 'globe']} />
                  <span className="link_name">Site Lister</span>
                </Link>
                <span className="tooltip">Site Lister</span>
              </li>
              )}
            </>
          )}
          
{(user?.allowBackupManagement && user.managingBackups!=="false") && (
  user?.mainPage !== "backups" &&(
          <li className={location.pathname === '/console/backups' ? 'activepage' : 'inactivepage'}>
            <Link to='/console/backups' onClick={handleLinkClick}>
              <FontAwesomeIcon className='listed icon' icon={['fas', 'box']} />
              <span className="link_name">Backups</span>
            </Link>
            <span className="tooltip">Backups</span>
          </li>
  )
)}

          <li className={location.pathname === '/console/settings' ? 'activepage' : 'inactivepage'}>
            <Link to='/console/settings' onClick={handleLinkClick}>
              <FontAwesomeIcon className='listed icon' icon={['fas', 'cog']} />
              <span className="link_name">Settings</span>
            </Link>
            <span className="tooltip">Settings</span>
          </li>

        </ul>
        <ul className='navfooter'>
          <li className="profile">
            <div className="profile_details">
              <FontAwesomeIcon className='listed icon' icon={['fas', 'user-alt']} />
              <div className="profile_content">
                <div className="name">
                  {user?.name !== "" ? user?.name : <Link to='/console/profile' onClick={handleLinkClick}>Add Name</Link>}
                </div>
                <div className="designation">{user?.position}</div>
              </div>
            </div>
            <FontAwesomeIcon id="log_out" onClick={handleLogout} className='listed icon' icon={['fas', 'person-running']} />
          </li>
        </ul>
      </div>
    )
  } else {
    return (<></>);
  }
};

export default Navbar;
