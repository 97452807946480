import React, { useState, useEffect, useContext } from 'react';
import { doc, setDoc, getDoc, getDocs, collection, updateDoc, deleteDoc } from 'firebase/firestore';
import { dbSlots, dbHrefsets } from '../../App'; // Ensure this points to your Firestore instance
import { UserContext } from '../../universal/context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Papa from 'papaparse';

interface Slot {
  id: string;
  gameName: string;
  gameType: string[];
  provider: string;
  popularPos: number;
  fileName: string;
  imageUrl: string;
  imageName: string;
  gameLink: string;
  reviewLinks: string[];
  lastSavedBy: string;
  lastUpdated: string; // Add this field
}

const SlotGamePage = () => {
  const { showNotification } = useNotification();
  const { showLoader, hideLoader, updateProgress } = useLoader();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [id, setId] = useState<string>('');
  const [gameName, setGameName] = useState<string>('');
  const [gameType, setGameType] = useState<string[]>([]);
  const [provider, setProvider] = useState<string>('');
  const [popularPos, setPopularPos] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [gameLink, setGameLink] = useState<string>('');
  const [reviewLinks, setReviewLinks] = useState<string[]>([]);
  const [slotsList, setSlotsList] = useState<Slot[]>([]);
  const [editingSlot, setEditingSlot] = useState<Slot | null>(null);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string>('');

  //CSV Matches
  const [numMatches, setNumMatches] = useState<number>(0);
  const [showMatchPopup, setShowMatchPopup] = useState<boolean>(false);
  

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [allSlotsList, setAllSlotsList] = useState<Slot[]>([]);
  const [sortCriteria, setSortCriteria] = useState<'popularPos' | 'lastUpdated'>('popularPos');

  const [gameTypes, setGameTypes] = useState<{ id: string; name: string }[]>([]);
  const [providers, setProviders] = useState<{ id: string; name: string }[]>([]);
  const [newGameType, setNewGameType] = useState<string>('');
  const [newProvider, setNewProvider] = useState<string>('');
  const [showNewGameTypePopup, setShowNewGameTypePopup] = useState<boolean>(false);
  const [showNewProviderPopup, setShowNewProviderPopup] = useState<boolean>(false);

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
const [slotToDelete, setSlotToDelete] = useState<Slot | null>(null);


  // Stores the image temporarily
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    //Check auths
    if (!user?.allowSlotCodeEditing) {
      navigate('/');
    }
  });

  useEffect(() => {
    fetchSlots();
    fetchGameTypes();
    fetchProviders();
  }, []);

  // Fetch Game Types
  const fetchGameTypes = async () => {
    const gameTypesCollectionRef = collection(dbSlots, 'gameTypes');
    try {
      const querySnapshot = await getDocs(gameTypesCollectionRef);
      const fetchedGameTypes = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as { name: string }
      }));
      setGameTypes(fetchedGameTypes);
    } catch (error) {
      console.error("Error fetching game types:", error);
    }
  };

  // Fetch Providers
  const fetchProviders = async () => {
    const providersCollectionRef = collection(dbSlots, 'providers');
    try {
      const querySnapshot = await getDocs(providersCollectionRef);
      const fetchedProviders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as { name: string }
      }));
      setProviders(fetchedProviders);
    } catch (error) {
      console.error("Error fetching providers:", error);
    }
  };

  const fetchSlots = async () => {
    showLoader();
    const slotsCollectionRef = collection(dbSlots, 'slots');
    try {
      const querySnapshot = await getDocs(slotsCollectionRef);
      const fetchedSlots = querySnapshot.docs.map((doc) => {
        const data = doc.data() as Omit<Slot, 'id'>;
        return { ...data, id: doc.id }; // Ensure id is only assigned once
      });
      fetchedSlots.sort((a, b) => {
        if (sortCriteria === 'popularPos') {
          if (a[sortCriteria] === 0) return 1; // Move a to the end
          if (b[sortCriteria] === 0) return -1; // Move b to the end
          return a[sortCriteria] - b[sortCriteria];
        } else {
          return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
        }
      });
      setAllSlotsList(fetchedSlots);
      setSlotsList(fetchedSlots);
    } catch (error) {
      console.error("Error fetching slots:", error);
    }
    hideLoader();
  };

  // Create new Slot Code
  const createSlot = async () => {
    showLoader();

    if (gameType.length === 0) {
      showNotification('Please select a game type!', 'error');
      hideLoader();
      return;
    }

    if (!gameType || !provider || !gameLink || !imageFile) {
      showNotification('All fields are required!', 'error');
      hideLoader();
      return;
    }

    const slotId = uuidv4();
    const slotsRef = doc(dbSlots, 'slots', slotId);

    try {
      let downloadURL = '';
      let fileName = '';

      if (imageFile) {
        fileName = `${Date.now()}-${imageFile.name}`;
        const imageRef = ref(storage, `images/${fileName}`);
        const snapshot = await uploadBytes(imageRef, imageFile);
        downloadURL = await getDownloadURL(snapshot.ref);
        setImageName(fileName);
      }

      let newPopularPos = popularPos;

      const querySnapshot = await getDocs(collection(dbSlots, 'slots'));
      const slots = querySnapshot.docs.map(doc => {
        const data = doc.data() as Omit<Slot, 'id'>;
        return { ...data, id: doc.id };
      });

      if (popularPos !== 0 && !slots.some(slot => slot.popularPos === popularPos)) {
        newPopularPos = Math.max(...slots.map(slot => slot.popularPos), 0) + 1;
      } else if (popularPos !== 0) {
        slots.forEach(async slot => {
          if (slot.popularPos >= popularPos) {
            await updateDoc(doc(dbSlots, 'slots', slot.id), { popularPos: slot.popularPos + 1 });
          }
        });
      }

      const slotData = {
        id: slotId,
        gameName,
        gameType,
        provider,
        popularPos: newPopularPos,
        imageUrl: downloadURL,
        gameLink,
        imageName: fileName,
        reviewLinks,
        lastSavedBy: user?.name || 'unknown',
        lastUpdated: new Date().toISOString(), // Add the current date and time
        fileName, // Add the fileName here
      };
      await setDoc(slotsRef, slotData);

      showNotification('Slot added successfully!', 'success');
      fetchSlots();
      resetForm();

    } catch (error) {
      showNotification('Failed to create or check slot!', 'error');
    }
    hideLoader();
  };

  const updateSlot = async () => {
    if (!editingSlot) return;
    showLoader();

    if (gameType.length === 0) {
      showNotification('Please select a game type!', 'error');
      hideLoader();
      return;
    }

    if (!gameType || !provider || !gameLink) {
      showNotification('All fields are required!', 'error');
      hideLoader();
      return;
    }

    const slotsRef = doc(dbSlots, 'slots', editingSlot.id);

    try {
      let downloadURL = imageUrl;
      let fileName = editingSlot.fileName; // Get the current fileName

      // If a new image file is provided, upload it and update the fileName and downloadURL
      if (imageFile) {
        // Generate new fileName
        fileName = `${Date.now()}-${imageFile.name}`;
        const imageRef = ref(storage, `images/${fileName}`);
        const snapshot = await uploadBytes(imageRef, imageFile);
        downloadURL = await getDownloadURL(snapshot.ref);

        // Delete old image if it exists
        const oldFileName = editingSlot.fileName;
        if (oldFileName) {
          const oldImageRef = ref(storage, `images/${oldFileName}`);
          await deleteObject(oldImageRef).catch((error) => {
            console.error('Error deleting old image:', error);
          });
        }

        // Update the image name
        setImageName(fileName);
      }

      let newPopularPos = popularPos;

      const querySnapshot = await getDocs(collection(dbSlots, 'slots'));
      const slots = querySnapshot.docs.map(doc => {
        const data = doc.data() as Omit<Slot, 'id'>;
        return { ...data, id: doc.id };
      }).filter(slot => slot.id !== editingSlot.id);

      if (editingSlot.popularPos !== 0 && popularPos === 0) {
        slots.forEach(async slot => {
          if (slot.popularPos > editingSlot.popularPos) {
            await updateDoc(doc(dbSlots, 'slots', slot.id), { popularPos: slot.popularPos - 1 });
          }
        });
      }

      if (popularPos !== 0 && popularPos !== editingSlot.popularPos) {
        if (!slots.some(slot => slot.popularPos === popularPos)) {
          newPopularPos = Math.max(...slots.map(slot => slot.popularPos), 0) + 1;
        } else {
          slots.forEach(async slot => {
            if (slot.popularPos >= popularPos) {
              await updateDoc(doc(dbSlots, 'slots', slot.id), { popularPos: slot.popularPos + 1 });
            }
          });
        }
      }

      const slotData = {
        id: editingSlot.id,
        gameName,
        gameType,
        provider,
        popularPos: newPopularPos,
        imageUrl: downloadURL,
        gameLink,
        imageName: fileName,
        reviewLinks,
        lastSavedBy: user?.name || 'unknown',
        lastUpdated: new Date().toISOString(), // Add the current date and time
        fileName, // Ensure fileName is added here
      };

      await updateDoc(slotsRef, slotData);

      showNotification('Slot updated successfully!', 'success');
      fetchSlots();
      resetForm();
    } catch (error) {
      showNotification('Failed to update slot!', 'error');
      console.error('Error updating slot:', error);
    }
    hideLoader();
  };

  //Delete Slot funtionality
  const handleDeleteSlotClick = (slot: Slot) => {
    setSlotToDelete(slot);
    setShowDeletePopup(true);
  };
  
  const confirmDeleteSlot = async () => {
    if (!slotToDelete) return;
    await deleteSlot(slotToDelete);
    setShowDeletePopup(false);
  };
  
  const cancelDeleteSlot = () => {
    setSlotToDelete(null);
    setShowDeletePopup(false);
  };
  

  const handleEditSlot = (slot: Slot) => {
    setEditingSlot(slot);
    setGameName(slot.gameName);
    setGameType(slot.gameType || []); // Ensure gameType is an array
    setProvider(slot.provider);
    setPopularPos(slot.popularPos);
    setImageUrl(slot.imageUrl);
    setGameLink(slot.gameLink);
    setReviewLinks(slot.reviewLinks || []);
    setFormVisible(true);
  };

  const resetForm = () => {
    setEditingSlot(null);
    setGameName('');
    setGameType([]); // Ensure gameType is reset to an empty array
    setProvider('');
    setPopularPos(0);
    setImageUrl('');
    setGameLink('');
    setReviewLinks([]);
    setImageFile(null);  // Clear image file state
    setImageName(''); // Clear image name state
    setFormVisible(false);
  };

  const closeForm = () => {
    if (!formVisible) {
      setFormVisible(!formVisible);
    } else {
      setFormVisible(!formVisible);
      resetForm();
    }
  };

  const handleCancelEdit = () => {
    resetForm();
  };

  const handleFilter = (query: string) => {
    setSearchQuery(query);
    applyFilters(query, sortCriteria);
  };

  // IMAGES
  const storage = getStorage();

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        if (img.width === 400 && img.height === 200) {
          setImageFile(file); // Temporarily store the file
          setImageUrl(img.src); // Show the image preview
          setImageName(file.name); // Set the image name
        } else {
          showNotification('Image must be 400x200 pixels in size.', 'error');
        }
      };
    }
  };

  const handleImageClick = () => {
    document.getElementById('imageUpload')?.click();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        if (img.width === 400 && img.height === 200) {
          setImageFile(file); // Temporarily store the file
          setImageUrl(img.src); // Show the image preview
        } else {
          showNotification('Image must be 400x200 pixels in size.', 'error');
        }
      };
    }
  };

  // HANDLING NEW PROVIDERS/GAME TYPES
  const handleShowNewGameTypePopup = () => setShowNewGameTypePopup(true);
  const handleShowNewProviderPopup = () => setShowNewProviderPopup(true);

  const handleHideNewGameTypePopup = () => {
    setShowNewGameTypePopup(false);
    setNewGameType('');
  };

  const handleHideNewProviderPopup = () => {
    setShowNewProviderPopup(false);
    setNewProvider('');
  };

  const handleAddGameType = async () => {
    if (!newGameType.trim()) {
      showNotification('Game type name cannot be empty!', 'error');
      return;
    }

    const newGameTypeRef = doc(dbSlots, 'gameTypes', newGameType.trim());

    try {
      const docSnapshot = await getDoc(newGameTypeRef);
      if (docSnapshot.exists()) {
        showNotification(`Game type ${newGameType.trim()} already exists!`, 'error');
      } else {
        await setDoc(newGameTypeRef, { name: newGameType.trim() });
        setGameTypes([...gameTypes, { id: newGameType.trim(), name: newGameType.trim() }]);
        handleHideNewGameTypePopup();
        showNotification(`Game type ${newGameType.trim()} added successfully!`, 'success');
      }
    } catch (error) {
      showNotification(`Error adding game type: ${error}`, 'error');
    }
  };

  const handleAddProvider = async () => {
    if (!newProvider.trim()) {
      showNotification('Provider name cannot be empty!', 'error');
      return;
    }

    const newProviderRef = doc(dbSlots, 'providers', newProvider.trim());

    try {
      const docSnapshot = await getDoc(newProviderRef);
      if (docSnapshot.exists()) {
        showNotification(`Provider ${newProvider.trim()} already exists!`, 'error');
      } else {
        await setDoc(newProviderRef, { name: newProvider.trim() });
        setProviders([...providers, { id: newProvider.trim(), name: newProvider.trim() }]);
        handleHideNewProviderPopup();
        showNotification(`Provider ${newProvider.trim()} added successfully!`, 'success');
      }
    } catch (error) {
      showNotification(`Error adding provider: ${error}`, 'error');
    }
  };

  const applyFilters = (query: string, sortCriteria: 'popularPos' | 'lastUpdated') => {
    let filtered = [...allSlotsList]; // Use allSlotsList for filtering

    if (query.trim() !== '') {
      filtered = filtered.filter(
        (slot) =>
          slot.gameName.toLowerCase().includes(query.toLowerCase()) ||
          slot.provider.toLowerCase().includes(query.toLowerCase()) ||
          slot.gameType.some(type => type.toLowerCase().includes(query.toLowerCase()))
      );
    }

    filtered.sort((a, b) => {
      if (sortCriteria === 'popularPos') {
        if (a[sortCriteria] === 0) return 1; // Move a to the end
        if (b[sortCriteria] === 0) return -1; // Move b to the end
        return a[sortCriteria] - b[sortCriteria];
      } else {
        return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
      }
    });

    setSlotsList(filtered);
  };

  // Add new Review link
  const addReviewLink = () => {
    setReviewLinks([...reviewLinks, '']);
  };

  // Remove Review Link
  const removeReviewLink = (index: number) => {
    const newReviewLinks = [...reviewLinks];
    newReviewLinks.splice(index, 1);
    setReviewLinks(newReviewLinks);
  };

  // Update Review Links
  const updateReviewLink = (index: number, value: string) => {
    const newReviewLinks = [...reviewLinks];
    newReviewLinks[index] = value;
    setReviewLinks(newReviewLinks);
  };

  // Fetch complete site list
  const fetchSiteList = async () => {
    try {
      const siteCollectionRef = collection(dbHrefsets, 'sites');
      const querySnapshot = await getDocs(siteCollectionRef);
      const sites = querySnapshot.docs.map(doc => doc.data() as { siteId: string, siteURL: string });
      return sites;
    } catch (error) {
      console.error('Error fetching site list:', error);
      return [];
    }
  };

  // Delete Slot
  const deleteSlot = async (slot: Slot) => {
    showLoader();
    if (!slot) return;

    const slotsRef = doc(dbSlots, 'slots', slot.id);

    try {
      // Extract the file name from the slot
      const fileName = slot.fileName;
      console.log(fileName);
      if (fileName) {
        // Delete image from storage
        const imageRef = ref(storage, `images/${fileName}`);
        await deleteObject(imageRef);
      }

      // Delete slot from Firestore
      await deleteDoc(slotsRef);

      showNotification('Slot deleted successfully!', 'success');
      hideLoader();
      fetchSlots();
      resetForm();
    } catch (error) {
      hideLoader();
      showNotification('Failed to delete slot!', 'error');
      console.error('Error deleting slot:', error);
    }
    hideLoader();
  };

  // Ping site to check if it's active
  const pingSite = async (siteURL: string) => {
    const token = process.env.REACT_APP_BEARER_TOKEN;
    try {
      const response = await fetch(`${siteURL}/wp-json/myplugin/v1/slotsactiveping`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.ok;
    } catch (error) {
      console.error("Error pinging site:", error);
      return false;
    }
  };

  const sendAllSlotsToWordPress = async () => {
    const slots = allSlotsList.map(slot => ({
      id: slot.id,
      gameName: slot.gameName,
      gameType: slot.gameType,
      provider: slot.provider,
      popularPos: slot.popularPos,
      imageUrl: slot.imageUrl,
      gameLink: slot.gameLink,
      imageName: slot.imageName,
      reviewLinks: slot.reviewLinks,
      lastSavedBy: slot.lastSavedBy,
      lastUpdated: slot.lastUpdated // Add lastUpdated field
    }));

    const sites = await fetchSiteList();
    const token = process.env.REACT_APP_BEARER_TOKEN;

    try {
      let processed = 0;
      let number = 0;
      let activeSites = [];

      // Check each site for active status
      for (const site of sites) {
        processed += 0.5;
        showLoader(`Checking site ${site.siteURL} for active Slots`, (processed / sites.length) * 100);
        const isActive = await pingSite(site.siteURL);
        if (isActive) {
          activeSites.push(site);
        }
        processed += 0.5;
        updateProgress((processed / sites.length) * 100);
      }

      if (activeSites.length === 0) {
        showNotification('No active sites found!', 'warning');
        hideLoader();
        return;
      }
      number = 1;
      processed = 0;
      let siteCount = activeSites.length;
      for (const site of activeSites) {
        processed += 0.5;
        showLoader(`Site ${number}/${siteCount}: Sending slots to ${site.siteURL}`, (processed / activeSites.length) * 100);
        const response = await fetch(`${site.siteURL}/wp-json/myplugin/v1/update-slots`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ slots })
        });

        processed += 0.5;
        number += 1;
        updateProgress((processed / sites.length) * 100);

        if (!response.ok) {
          console.error(`Error updating slots on ${site.siteURL}:`, response.statusText);
        } else {
          const result = await response.json();
          console.log(`Success updating slots on ${site.siteURL}:`, result);
        }
      }

      showNotification('All slots sent successfully!', 'success');
    } catch (error) {
      console.error('Error sending all slots:', error);
      showNotification('Failed to send all slots!', 'error');
    } finally {
      hideLoader();
    }
  };

  // Added the handleCSVUpload function
  const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const data = results.data as string[][];
          const gameNameSlug = gameName.toLowerCase().replace(/ /g, '-');
          
          const newLinks: string[] = [];
          
          data.forEach((row) => {
            const matchingLinks = row.filter(cell => cell.includes(gameNameSlug));
            newLinks.push(...matchingLinks);
          });
  
          setReviewLinks([...reviewLinks, ...newLinks]);
          setNumMatches(newLinks.length);
          setShowMatchPopup(true);
          (event.target as HTMLInputElement).value = ''; // Clear the file input
        },
        header: false,
      });
    }
  };
  
  

  return (
    <div className="dashpanel">
      <div className="main">
        <div className="head">
          {showNewGameTypePopup && (
            <div className="edit-overlay z-ind-25">
              <div className="delete-confirmation-popup">
                <div className="add-client-form edit-hreflang">
                  <h3>Add New Game Type</h3>
                  <label>Game Type Name</label>
                  <input
                    type="text"
                    value={newGameType}
                    onChange={(e) => setNewGameType(e.target.value)}
                    placeholder="New Game Type Name"
                  />
                  <div className='buttonsetcontainer hrefpopup'>
                    <button style={{ width: 150 }} className='sitemanagerbutton addhref chunkpadded' onClick={handleAddGameType}>Add Game Type</button>
                    <button style={{ width: 150 }} className='sitemanagerbutton deletehref chunkpadded' onClick={handleHideNewGameTypePopup}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showDeletePopup && (
            <div className="edit-overlay z-ind-25">
              <div className="delete-confirmation-popup">
                <div className="add-client-form edit-hreflang">
                  <h3>Confirm Delete</h3>
                  <p>Are you sure you want to delete this slot?</p>
                  <div className='buttonsetcontainer hrefpopup'>
                    <button style={{ width: 150 }} className='sitemanagerbutton deletehref chunkpadded' onClick={confirmDeleteSlot}>Delete</button>
                    <button style={{ width: 150 }} className='sitemanagerbutton chunkpadded' onClick={cancelDeleteSlot}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showMatchPopup && (
            <div className="edit-overlay z-ind-25">
            <div className="delete-confirmation-popup">
              <div style={{textAlign:"center"}} className="add-client-form edit-hreflang">
                <h2>CSV Upload</h2>
                <p>{numMatches} matches found.</p>
                <button style={{width: 150}} className='sitemanagerbutton chunkpadded' onClick={() => setShowMatchPopup(false)}>Close</button>
              </div>
            </div>
            </div>
          )}

          {showNewProviderPopup && (
            <div className="edit-overlay z-ind-25">
              <div className="delete-confirmation-popup">
                <div className="add-client-form edit-hreflang">
                  <h3>Add New Provider</h3>
                  <label>Provider Name</label>
                  <input
                    type="text"
                    value={newProvider}
                    onChange={(e) => setNewProvider(e.target.value)}
                    placeholder="New Provider Name"
                  />
                  <div className='buttonsetcontainer hrefpopup'>
                    <button style={{ width: 150 }} className='sitemanagerbutton addhref chunkpadded' onClick={handleAddProvider}>Add Provider</button>
                    <button style={{ width: 150 }} className='sitemanagerbutton deletehref chunkpadded' onClick={handleHideNewProviderPopup}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <h1>Slot Games</h1>
          <h2>Manage your slot games here.</h2>
          <button className={formVisible ? 'termsbuttonstyleremove' : 'termsbuttonstyleadd'} onClick={() => closeForm()}>
            {formVisible ? 'Hide Slot Form' : 'Show Slot Form'}
          </button>
          {!formVisible && (
            <button className='sitemanagerbutton' onClick={sendAllSlotsToWordPress}>
              Publish Slots
            </button>
          )}

          {formVisible && (
            <div className="add-client-form">
              <h3>{editingSlot ? 'Edit Slot Game' : 'Add New Slot Game'}</h3>
              <label>Game Name</label>
              <input type="text" value={gameName} onChange={(e) => setGameName(e.target.value)} placeholder="Game Name" />
              <label>Image</label>
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: '2px dashed #ccc',
                  borderRadius: '10px',
                  padding: '20px',
                  textAlign: 'center',
                  marginBottom: '20px',
                  cursor: 'pointer'
                }}
                onClick={handleImageClick}
              >
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <img
                  src={imageUrl}
                  alt="Upload Image"
                  style={{ maxWidth: '200px', maxHeight: '100px', borderRadius: '10px' }}
                />
                <p>Click or Drag and Drop to upload image (400x200px)</p>
              </div>

              <div style={{ alignItems: 'center', marginBottom: '15px' }} className='flatcat'>
                <label style={{ marginBottom: '0' }}>Game Type</label>
                <div style={{ marginLeft: '10px', marginBottom: 0, paddingLeft: '6px', paddingRight: '6px', paddingTop: '3px', paddingBottom: '3px' }} className="plus" onClick={handleShowNewGameTypePopup}>+</div>
              </div>
              <div className="flatcat gameType-checkboxes">
                <div style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap', }}>
                  {gameTypes.map((type) => (
                    <div key={type.id} className="checkbox-item">
                      <input
                        type="checkbox"
                        id={type.id}
                        value={type.id}
                        checked={gameType.includes(type.id)}
                        onChange={(e) => {
                          const selectedType = e.target.value;
                          if (e.target.checked) {
                            setGameType([...gameType, selectedType]);
                          } else {
                            setGameType(gameType.filter((type) => type !== selectedType));
                          }
                        }}
                      />
                      <label htmlFor={type.id}>{type.name}</label>
                    </div>
                  ))}
                </div>
              </div>

              <label>Provider</label>
              <div className='flatcat'>
                <select
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                >
                  <option value="">Select a provider</option>
                  {providers.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                      {provider.name}
                    </option>
                  ))}
                </select>
                <div style={{ marginBottom: 10 }} className='plus' onClick={handleShowNewProviderPopup}>+</div>
              </div>
              <label>Popular Position</label>
             
              <input type="number" value={popularPos} onChange={(e) => setPopularPos(parseInt(e.target.value))} placeholder="Popular Position" />
              <label>Image URL</label>
              <input disabled value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} placeholder="Image URL" />
              <label>Game Link</label>
              <input value={gameLink} onChange={(e) => setGameLink(e.target.value)} placeholder="Game Link" />
              <div style={{marginTop:"30px",marginBottom:"30px",borderTop:"3px dashed lightgray",paddingTop:"10px",borderBottom:"3px dashed lightgray",paddingBottom:"5px"}}>
              <label style={{marginBottom:"0"}}>CSV Review Links</label>
              <p style={{fontSize:"10px",color:"darkred",paddingTop:"5px",paddingBottom:"5px",textAlign:"left",marginTop:"0"}}><u><b>EXPERIMENTAL!</b></u> Use with caution and double check work</p>
              <input type="file" accept=".csv" onChange={handleCSVUpload} />
              </div>
              
              

              <div className='flatcat flatser'><label>Review Links</label><button className='plus plusser' onClick={addReviewLink}>+</button></div>
              {reviewLinks.map((link, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                  <input
                    value={link}
                    onChange={(e) => updateReviewLink(index, e.target.value)}
                    placeholder="Review URL"
                    style={{ marginRight: '10px', width: '100%' }}
                  />
                  <button className='sitemanagerbutton deletehref addlink' style={{ width: '15%' }} onClick={() => removeReviewLink(index)}>-</button>
                </div>
              ))}

              <button className='sitemanagerbutton empty' onClick={editingSlot ? updateSlot : createSlot}>
                {editingSlot ? 'Update Slot' : 'Add New Slot'}
              </button>
              {editingSlot && (
                <>
                  <button style={{ margin: '10px 0' }} className='sitemanagerbutton selectedhref' onClick={handleCancelEdit}>
                    Cancel
                  </button>
                  <button style={{ margin: '10px 0' }} className='sitemanagerbutton deletehref' onClick={() => handleDeleteSlotClick(editingSlot)}>Delete</button>
                </>
              )}
            </div>
          )}
          {!formVisible && (
            <>
              <div style={{ width: 350, padding: 5, display: 'flex' }} className="add-client-form edit-hreflang">
                <input style={{ marginBottom: 0 }} type="text" value={searchQuery} onChange={(e) => handleFilter(e.target.value)} placeholder="Search Slots" />
              </div>
              <div className='slot-list'>
                <h2>Slot Games List</h2>
                <div className='slot-grid'>
                  {slotsList.map((slot) => (
                    <div key={slot.id} className='slot-item'>
                      <img src={slot.imageUrl} />
                      <p><b>Name:</b> {slot.gameName}</p>
                      <p><b>Type:</b> {slot.gameType.join(', ')}</p>
                      <p><b>Provider:</b> {slot.provider}</p>
                      <p style={{ fontSize: '10px' }}>Sorting</p>
                      <p><b>Popular:</b> {slot.popularPos === 0 ? 'N/A' : slot.popularPos}</p>
                      <p><b>Last Updated:</b> {new Date(slot.lastUpdated).toLocaleString()}</p> {/* Display last updated date */}
                      <button className='sitemanagerbutton selectedhref' onClick={() => handleEditSlot(slot)}>Edit</button>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlotGamePage;
