import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import Notification from '../services/Notification';

interface NotificationContextProps {
  showNotification: (message: string, type: 'success' | 'error' | 'warning') => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotification = (): NotificationContextProps => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<
    { message: string; type: 'success' | 'error' | 'warning'; id: number }[]
  >([]);

  const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
    const id = Date.now();
    console.log(`Notification ${id} will be added`);
    setNotifications((prev) => {
      const newNotifications = [{ message, type, id }, ...prev];
      console.log(`Notifications list: ${newNotifications.map(n => n.id).join(', ')}`);
      if (newNotifications.length > 5) {
        const removed = newNotifications.pop();
        console.log(`Notification ${removed?.id} removed to maintain limit`);
      }
      return newNotifications;
    });
  };

  const removeNotification = useCallback((id: number) => {
    console.log(`Removing notification ${id}`);
    setNotifications((prev) => {
      const remainingNotifications = prev.filter((notification) => notification.id !== id);
      console.log(`Notifications list after removal: ${remainingNotifications.map(n => n.id).join(', ')}`);
      return remainingNotifications;
    });
  }, []);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <div className="notification-container" style={{ position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
        {notifications.map((notification, index) => (
          <Notification
            key={notification.id}
            id={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={removeNotification}
            index={index} // Pass index to each Notification
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};
