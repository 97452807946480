import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../universal/context/UserContext';
import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';
import { useNavigate } from 'react-router-dom';

// Define the type for backup items
interface Backup {
  databaseId: string;
  dateSuffix: string;
}

const RestoreBackup: React.FC = () => {
  const [backups, setBackups] = useState<Backup[]>([]);
  const [status, setStatus] = useState<string>('');
  const [showBackups, setShowBackups] = useState<boolean>(false);

  const { showLoader, hideLoader } = useLoader();
  const { showNotification } = useNotification();
  const token = process.env.REACT_APP_SECURE_TOKEN;

  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  //Intial Calls
  useEffect(() => {
    //Check auths
    if (!user?.allowBackupManagement) {
      navigate('/');
    }
  });

  const fetchBackups = async () => {
    showLoader();
    try {
      const response = await fetch(`https://us-central1-dream-lead.cloudfunctions.net/listFirestoreBackups?token=${token}`);
      const data: Backup[] = await response.json();
      setBackups(data);
      hideLoader();
    } catch (error) {
      if (error instanceof Error) {
        setStatus(`Error fetching backups: ${error.message}`);
      } else {
        setStatus('Error fetching backups');
      }
      hideLoader();
    }
  };

  const handleViewBackups = () => {
    setShowBackups(true);
    fetchBackups();
  };

  const handleRestore = async (databaseId: string, dateSuffix: string) => {
    showLoader();
    try {
      const response = await fetch(`https://us-central1-dream-lead.cloudfunctions.net/restoreFirestoreBackup?databaseId=${databaseId}&dateSuffix=${dateSuffix}&token=${token}`);
      const result = await response.text();
      setStatus(result);
      hideLoader();
      showNotification('Backup restored successfully!', 'success');
    } catch (error) {
      if (error instanceof Error) {
        setStatus(`Error: ${error.message}`);
      } else {
        setStatus('Error restoring backup');
      }
      hideLoader();
      showNotification('Error restoring backup!', 'error');
    }
  };

  const handleTakeBackup = async () => {
    showLoader();
    try {
      const response = await fetch(`https://us-central1-dream-lead.cloudfunctions.net/triggerBackupOnDemand?token=${token}`, {
        method: 'POST',
      });
      const result = await response.text();
      setStatus(result);
      hideLoader();
      showNotification('Backup taken successfully!', 'success');
      // Optionally, refresh the backups list after taking a backup
      fetchBackups();
    } catch (error) {
      if (error instanceof Error) {
        setStatus(`Error taking backup: ${error.message}`);
      } else {
        setStatus('Error taking backup');
      }
      hideLoader();
      showNotification('Error taking backup!', 'error');
    }
  };

  return (
    <div className="dashpanel">
      <div className="main">
        <div className="head">
          <h1>Restore Backups</h1>
          <h2>
            View and restore the <span className="usertext">Backup List</span>.
          </h2>
          <button className='sitemanagerbutton selectedhref' onClick={handleTakeBackup}>Take Backup</button>
          {!showBackups ? (
            <button className='sitemanagerbutton selectedhref' onClick={handleViewBackups}>View Backups</button>
          ) : (
            <>
              {backups.length > 0 ? (
                <ul>
                  {backups.map((backup, index) => (
                    <li key={index}>
                      <span>{backup.databaseId} - {backup.dateSuffix}</span>
                      <button onClick={() => handleRestore(backup.databaseId, backup.dateSuffix)}>Restore</button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No backups available</p>
              )}
            </>
          )}
          {status && <div>{status}</div>}
        </div>
      </div>
    </div>
  );
};

export default RestoreBackup;
