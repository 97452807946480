import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getAuth } from 'firebase/auth';
import { db } from '../../App'; // Ensure this points to Firestore instance
import { collection, doc, getDocs, setDoc, Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";

const Clients: React.FC = () => {
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [regDate, setRegDate] = useState<string>('');
  const [tempPassword, setTempPassword] = useState<string | null>(null);

  const [allowCasinoEditing, setAllowCasinoEditing] = useState<boolean>(false);
  const [allowSlotCodeEditing, setAllowSlotCodeEditing] = useState<boolean>(false);
  const [allowHreflangSetEditing, setAllowHreflangSetEditing] = useState<boolean>(false);
  const [allowBackupManagement, setAllowBackupManagement] = useState<boolean>(false);

  // For search functionality
  const [searchTerm, setSearchTerm] = useState('');

  // For pagination
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const filteredClients = clients.filter(client => {
    const searchWords = searchTerm.toLowerCase().split(' ');

    return searchWords.every(word => 
      client.name.toLowerCase().includes(word) ||
      client.surname.toLowerCase().includes(word)
    );
  });

  // Pagination logic
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  const handleAddClient = async () => {
    setLoading(true);
    console.log("Starting to add a client...");

    // Validate form data
    if (!name || !surname || !email || !regDate) {
      console.log("Name:", name);
      console.log("Surname:", surname);
      console.log("Email:", email);
      alert("Please fill all the details!");
      setLoading(false);
      return;
    }
    let actionButtonAction = "";
    if(allowSlotCodeEditing){
      actionButtonAction = "add-casino";
    }

    // Generate Password
    const password = uuidv4();
    console.log(`Generated temp password: ${password}`);

    try {
      // Call the cloud function to create the user
      const createUserFunction = async (email: string, password: string) => {
        const functions = getFunctions();
        const createUser = httpsCallable(functions, 'createUser');
        const result = await createUser({ email, password });
        return result.data as { uid: string };
      };

      const data = await createUserFunction(email, password);
      const clientUUID = data.uid;
      const regDateObj = new Date(regDate);
      // Save user details in Firestore
      await setDoc(doc(db, 'users', clientUUID), {
        id: clientUUID,
        name,
        surname,
        passwordSet: false,
        email,
        regDate: Timestamp.fromDate(regDateObj), // Use Timestamp directly
        position: 'User',
        role: 'user',
        registeredManagerID: 'NOMBLE',
        allowCasinoEditing: allowCasinoEditing,
        allowSlotCodeEditing: allowSlotCodeEditing,
        allowHreflangSetEditing: allowHreflangSetEditing,
        allowBackupManagement: allowBackupManagement,
        mainPage: "dashboard",
        actionButton: 'add-casino',
        casinoEditing: allowCasinoEditing,
        slotCodeEditing: allowSlotCodeEditing,
        managingBackups: allowBackupManagement,
        hreflangSetEditing: allowHreflangSetEditing,
        mainPageNotice: true,
        navbarIconsEnabled: 'false'
      });

      setTempPassword(password);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error adding client:", error.message);
        alert("Error adding client: " + error.message);
      } else {
        console.error("Unknown error adding client:", error);
        alert("Error adding client");
      }
    }
    await fetchClients();
    setLoading(false);
  };

  const fetchClients = async () => {
    setLoading(true);
    const clientsSnapshot = await getDocs(collection(db, 'users'));
    const clientArray: any[] = [];
  
    clientsSnapshot.forEach(doc => {
      const clientData = doc.data();
      clientArray.push({ id: doc.id, ...clientData });
    });
  
    setClients(clientArray);
    setLoading(false);
  };

  useEffect(() => {
    const fetchClients = async () => {
      const clientsSnapshot = await getDocs(collection(db, 'users'));
      const clientArray: any[] = [];
    
      clientsSnapshot.forEach(doc => {
        const clientData = doc.data();
        clientArray.push({ id: doc.id, ...clientData });
      });
    
      setClients(clientArray);
      setLoading(false);
    };
    console.log("Logged-in user's UID:", auth.currentUser?.uid);
    
    fetchClients();
  }, []);

  return (
    <div className="main">
      <div className="head">
        <h1>User Management</h1>
        <div className="add-client-form">
          <h3>Add New User</h3>
          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <input type="text" placeholder="Surname" value={surname} onChange={(e) => setSurname(e.target.value)} />
          <label>Registration Date:</label>
          <input type="date" placeholder="Date" value={regDate} onChange={(e) => setRegDate(e.target.value)} />
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label>
            <input 
              type="checkbox" 
              checked={allowCasinoEditing} 
              onChange={(e) => setAllowCasinoEditing(e.target.checked)} 
            /> Allow Casino Editing
          </label>
          <label>
            <input 
              type="checkbox" 
              checked={allowSlotCodeEditing} 
              onChange={(e) => setAllowSlotCodeEditing(e.target.checked)} 
            /> Allow Slot Code Editing
          </label>
          <label>
            <input 
              type="checkbox" 
              checked={allowHreflangSetEditing} 
              onChange={(e) => setAllowHreflangSetEditing(e.target.checked)} 
            /> Allow Hreflang Set Editing
          </label>
          <label>
            <input 
              type="checkbox" 
              checked={allowBackupManagement} 
              onChange={(e) => setAllowBackupManagement(e.target.checked)} 
            /> Allow Backup Management
          </label>
          <button onClick={handleAddClient}>Add User</button>
          {tempPassword && <p>Temporary Password: {tempPassword}</p>}
        </div>
        <br />
        <div className="search-bar">
          <input 
            type="text" 
            placeholder="Search for a user..." 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <br />
        <div className="client-stats-container">
          <div className="client-stat-box">
            <table className="client-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  <th>Position</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? 
                  <tr>
                    <td colSpan={5} style={{textAlign: 'center', verticalAlign: 'middle', height: '150px'}}>
                      <div className="loader"></div>
                    </td>
                  </tr>
                : (filteredClients.length === 0 && !loading ? 
                  <tr>
                    <td colSpan={5} style={{textAlign: 'center', verticalAlign: 'middle', height: '150px'}}>
                      This client does not exist
                    </td>
                  </tr>
                : currentClients.map((client) => (
                  <tr key={client.id}>
                    <td>{client.name}</td>
                    <td>{client.surname}</td>
                    <td>{client.email}</td>
                    <td>{client.position}</td>
                    <td>
                      <button className="delete-button" onClick={() => navigate(`/admin/modify-user/${client.id}`)}>Edit</button>
                    </td>
                  </tr>
                )))
                }
              </tbody>
            </table>

            {filteredClients.length > clientsPerPage && 
              <div className="pagination">
                <button onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}>Previous</button>
                <span>{currentPage}</span>
                <button onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(filteredClients.length / clientsPerPage)))}>Next</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
