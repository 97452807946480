// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';

/*Style Imports*/

//Old import universal styles {TO BE DEPRECIATED}
import './index.css';

//Import universal styles
import './styles/universal.css'

//Import Loader Style
import './styles/loader.css';

//Import BetterBonus Table Style
import './styles/betterbonusstyle.css'

//Import Notification Styles
import './styles/notification.css';

//Import Dashboard Styles
import './styles/dashboard.css';

//Import Slot Styles
import './styles/slots.css';

import App from './App';

import { LoaderProvider } from './universal/context/LoaderContext';
import { NotificationProvider } from './universal/context/NotificationContext';

import Loader from './universal/services/Loader';


import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  /*
  <React.StrictMode>
    <App />
  </React.StrictMode>
  */
  <NotificationProvider>
  <LoaderProvider>
  <Loader />
  <App />
</LoaderProvider>
</NotificationProvider>

      
      
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
