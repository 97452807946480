// universal/pages/Loader.tsx
import React from 'react';
import { useLoader } from '../context/LoaderContext';

const Loader: React.FC = () => {
    const { loading, progress, message } = useLoader();

    if (!loading) return null;

    return (
        <div className="neoloader-overlay">
            <div className="neoloader">
                <img src='/logos/nomble-halfcircle-blue.png' alt="Loading" />
                {message && <p className="loader-message">{message}</p>}
                {progress !== null && message &&(
                    <div className="loader-progress">
                        <div className="loader-progress-bar" style={{ width: `${progress}%` }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Loader;
