import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../../App';
import { UserContext } from '../../universal/context/UserContext';

const ProfilePage = () => {
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);//loading
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }else if (user?.role == 'client'){
        navigate('/clientapp');
    }
    setLoading(false);
  }, [user, navigate]);


  return (
    <div className="main">
      <div className="head">
        <h1>
          Welcome Back <span className="usertext">{user?.role}</span>!
        </h1>
        <h2>Quick Stats</h2>
        <div className="statcontainer">
          <div className="stat blob">
            <h3>Stat</h3>
          </div>
          <div className="stat blob">
            <h3>Stat</h3>
          </div>
          <div className="stat blob">
            <h3>Stat</h3>
          </div>
          <div className="stat blob">
            <h3>Stat</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
