import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../../App';
import { UserContext } from '../../universal/context/UserContext';

const Main: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const auth = getAuth();  
  const userID = auth.currentUser?.uid;
  const UserEmail = auth.currentUser?.email;


  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => {
        setUser(null); // Reset user state after logout
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  useEffect(() => {
    AuthCheck()
}, [auth])

const AuthCheck = onAuthStateChanged(auth, (user) => {
    if (!user) {
        console.log('Unauthorized');
        navigate('/');
    }
})

  if (!user) {
    return null; // Return null to prevent rendering anything else in the component
  }

  return (
    <div className='main'>
      <div className='head'>
        <h1>Welcome Admin <span className='usertext'>{UserEmail}</span>!</h1>
        <h2>Quick Stats</h2>
        <div className='statcontainer'>
        <div className='stat blob'>
          <h3>Stat</h3>
        </div>
        <div className='stat blob'>
          <h3>Stat</h3>
        </div>
        <div className='stat blob'>
          <h3>Stat</h3>
        </div>
        <div className='stat blob'>
          <h3>Stat</h3>
          
        </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
