import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../../App';
import { UserContext } from '../../universal/context/UserContext';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';

const SettingsPage = () => {

  const { showLoader, hideLoader } = useLoader();
  const { showNotification } = useNotification();

  const { user, setUser } = useContext(UserContext);
  const [mainPage, setMainPage] = useState('');
  const [actionButton, setActionButton] = useState('');
  
  const [navbarIconsEnabled, setNavbarIconsEnabled] = useState('false'); // Add this line


  const [casinoEditing, setCasinoEditing] = useState('');
  const [slotCodeEditing, setSlotCodeEditing] = useState('');
  const [hreflangSetEditing, setHreflangSetEditing] = useState('');
  const [managingBackups, setManagingBackups] = useState('');
  const [mainPageNotice, setMainPageNotice] = useState('');



  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    showLoader();
    if (user) {
      if (user.mainPage) {
        setMainPage(user.mainPage);
      }
      if (user.actionButton) {
        setActionButton(user.actionButton);
      }
      if (user.casinoEditing) {
        setCasinoEditing(user.casinoEditing);
      }
      if (user.slotCodeEditing) {
        setSlotCodeEditing(user.slotCodeEditing);
      }
      if (user.hreflangSetEditing) {
        setHreflangSetEditing(user.hreflangSetEditing);
      }
      if (user.mainPageNotice) {
        setMainPageNotice(user.mainPageNotice);
      }
      if (user.managingBackups) {
        setManagingBackups(user.managingBackups);
      }
      if (user.navbarIconsEnabled) {
        setNavbarIconsEnabled(user.navbarIconsEnabled);
      }
      
    }
    hideLoader();
  }, [user]);
  
  const handleActionButtonChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newActionButton = e.target.value;
    setActionButton(newActionButton);

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { actionButton: newActionButton });

      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: user.mainPage ?? '',
        actionButton: newActionButton,
        casinoEditing: user.casinoEditing ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: user.mainPageNotice ?? '',
        navbarIconsEnabled: user.navbarIconsEnabled ?? 'false' // Add this line
      });
    }
    hideLoader();
    showNotification('Action button modified!', 'success');
  };

  const handleNavbarIconsEnabledChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newNavbarIconsEnabled = e.target.value;
    setNavbarIconsEnabled(newNavbarIconsEnabled);
  
    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { navbarIconsEnabled: newNavbarIconsEnabled });
      
      setUser({
        ...user,
        navbarIconsEnabled: newNavbarIconsEnabled,
      });
    }
    hideLoader();
    showNotification('Navbar icons setting changed!', 'success');
  };
  

  const handleMainPageChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newMainPage = e.target.value;

    if(newMainPage === "slots" && slotCodeEditing === "false"){
      hideLoader();
      showNotification('You can\'t select this in dashboard since it\'s disabled!', 'error');
      return;
    }
    if(newMainPage === "sitelister" && hreflangSetEditing === "false"){
      hideLoader();
      showNotification('You can\'t select this in dashboard since it\'s disabled!', 'error');
      return;
    }

    if(newMainPage === "hreflangs" && hreflangSetEditing === "false"){
      hideLoader();
      showNotification('You can\'t select this in dashboard since it\'s disabled!', 'error');
      return;
    }

    if(newMainPage === "casinos" && casinoEditing === "false"){
      hideLoader();
      showNotification('You can\'t select this in dashboard since it\'s disabled!', 'error');
      return;
    }

    if(newMainPage === "backups" && managingBackups === "false"){
      hideLoader();
      showNotification('You can\'t select this in dashboard since it\'s disabled!', 'error');
      return;
    }
    
    if(newMainPage === "dashboard"){
    
    setMainPage(newMainPage);
    setMainPageNotice('true');

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { mainPage: newMainPage, mainPageNotice: 'true' });

      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: newMainPage,
        actionButton:  user.actionButton ?? '',
        casinoEditing: user.casinoEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: 'true'
      });
    }
  }
  else{
    setMainPage(newMainPage);

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { mainPage: newMainPage, mainPageNotice: mainPageNotice });

      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: newMainPage,
        actionButton:  user.actionButton ?? '',
        casinoEditing: user.casinoEditing ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: mainPageNotice ?? ''
      });
    }
  }
    hideLoader();
    showNotification('Main page changed!', 'success');
  };

  //Editors
  const handleCasinoEditingChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newCasinoEditing = e.target.value;
    
    if(mainPage === "casinos" && newCasinoEditing === "false"){
      showNotification('You can\'t disable this since it\'s selected in dashboard!', 'error');
      hideLoader();
      return;
    }

    setCasinoEditing(newCasinoEditing);
    
    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { casinoEditing: newCasinoEditing });

      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: user.mainPage ?? '',
        actionButton:  user.actionButton ?? '',
        casinoEditing: newCasinoEditing ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: user.mainPageNotice ?? ''
      });
    }
    hideLoader();
    showNotification('Casino editor status changed!', 'success');
  };

  const handleSlotCodeEditingChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newSlotCodeEditing = e.target.value;

    if(mainPage === "slots" && newSlotCodeEditing === "false"){
      showNotification('You can\'t disable this since it\'s selected in dashboard!', 'error');
      hideLoader();
      return;
    }

    setSlotCodeEditing(newSlotCodeEditing);

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { slotCodeEditing: newSlotCodeEditing });
      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: user.mainPage ?? '',
        actionButton: user.actionButton ?? '',
        casinoEditing: user.casinoEditing ?? '',
        slotCodeEditing: newSlotCodeEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: user.mainPageNotice ?? ''
      });
    }
    hideLoader();
    showNotification('Slot code status changed!', 'success');
  };

  const handleHreflangSetEditing = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newHreflangSetEditing = e.target.value;

    if(mainPage === "hreflangs" || mainPage === "sitelister" && newHreflangSetEditing === "false"){
      hideLoader();
      showNotification('You can\'t disable this since it\'s selected in dashboard!', 'error');
      return;
    }
    
    setHreflangSetEditing(newHreflangSetEditing);

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { hreflangSetEditing: newHreflangSetEditing });
      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: user.mainPage ?? '',
        actionButton: user.actionButton ?? '',
        casinoEditing: user.casinoEditing ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        hreflangSetEditing: newHreflangSetEditing ?? '',
        mainPageNotice: user.mainPageNotice ?? ''
      });
    }
    hideLoader();
    showNotification('Hreflang set editor status changed!', 'success');
  };

  const handleMainPageNoticdChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newMainPageNotice = e.target.value;
    
    setMainPageNotice(newMainPageNotice);

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { mainPageNotice: newMainPageNotice });
      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: user.mainPage ?? '',
        actionButton: user.actionButton ?? '',
        casinoEditing: user.casinoEditing ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        managingBackups: user.managingBackups ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: newMainPageNotice ?? ''
      });
    }
    hideLoader();
    showNotification('Hreflang set editor status changed!', 'success');
  };

  const handleManagingBackupsChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    showLoader();
    const newManagingBackups = e.target.value;

    if(mainPage === "backups" && newManagingBackups === "false"){
      hideLoader();
      showNotification('You can\'t disable this since it\'s selected in dashboard!', 'error');
      return;
    }
    
    setManagingBackups(newManagingBackups);

    const currentUser = auth.currentUser;
    if (currentUser && user) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { managingBackups: newManagingBackups });
      // Ensure all properties are set
      setUser({
        ...user,
        email: user.email ?? '',
        name: user.name ?? '',
        surname: user.surname ?? '',
        passwordSet: user.passwordSet ?? false,
        position: user.position ?? '',
        role: user.role ?? '',
        regdate: user.regdate ?? new Date(),
        allowCasinoEditing: user.allowCasinoEditing ?? false,
        allowSlotCodeEditing: user.allowSlotCodeEditing ?? false,
        allowHreflangSetEditing: user.allowHreflangSetEditing ?? false,
        allowBackupManagement: user.allowBackupManagement ?? false,
        mainPage: user.mainPage ?? '',
        actionButton: user.actionButton ?? '',
        casinoEditing: user.casinoEditing ?? '',
        slotCodeEditing: user.slotCodeEditing ?? '',
        managingBackups: newManagingBackups ?? '',
        hreflangSetEditing: user.hreflangSetEditing ?? '',
        mainPageNotice: user.mainPageNotice ?? ''
      });
    }
    hideLoader();
    showNotification('Backup Management status changed!', 'success');
  };

  return (
    <div className="dashpanel">
      <div className="main">
        <div className="head">
          <h1>
            Settings
          </h1>
          <h4>Set up default modules across <span className="usertext">nomble</span></h4>
          <br/>
          <h2>Main Page Module</h2>
        <div className='settingscontainer' style={{}}>
          <div style={{display:'flex',flexWrap:'wrap',flexDirection:'row',alignItems:'center'}}>
        <div style={{width:'30%',margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="mainPage">Select Main Page:</label>
          <select id="mainPage" value={mainPage} onChange={handleMainPageChange}>
            <option value="dashboard">Dashboard</option>
            {user?.allowCasinoEditing && (
              <option value="casinos">Casinos</option>
            )}
            {user?.allowSlotCodeEditing && (
              <option value="slots">Slots</option>
            )};
            {user?.allowHreflangSetEditing && (
            <>
              <option value="hreflangs">Hreflangs</option>
              <option value="sitelister">Site Lister</option>
            </>
            )};
            {user?.allowBackupManagement && (
            <>
              <option value="backups">Backup Management</option>
            </>
            )};
          </select>
        </div>
        <div style={{margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="mainPage">Set main page notice: [<i>Disabled Temporarily</i>]</label>
          <select id="mainPage" disabled value={mainPageNotice} onChange={handleMainPageNoticdChange}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
        </div>
        <br/>
        <h2>Functions Module</h2>
        <div style={{display:'flex',flexDirection:'row'}}>
        {user?.allowCasinoEditing && (
        <div style={{margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="casinoEditing">Casino Editing</label>
          <select id="casinoEditing" value={casinoEditing} onChange={handleCasinoEditingChange}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
        )}
        {user?.allowSlotCodeEditing && (
        <div style={{margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="slotCodeEditing">Slot Code Editing</label>
          <select id="slotCodeEditing" value={slotCodeEditing} onChange={handleSlotCodeEditingChange}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
        )}
        {user?.allowHreflangSetEditing && (
        <div style={{margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="hreflangSetEditing">Hreflang Set Editing</label>
          <select id="hreflangSetEditing" value={hreflangSetEditing} onChange={handleHreflangSetEditing}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
        )}
        {user?.allowBackupManagement && (
        <div style={{margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="managingBackups">Backup Management</label>
          <select id="managingBackups" value={managingBackups} onChange={handleManagingBackupsChange}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
        )}
        </div>
        {user?.allowCasinoEditing && user?.casinoEditing === "true" && (
          <>
        <br/>
        
        <h2>Action Button Module</h2>
        <div style={{display:'flex',flexDirection:'row'}} >
        <div style={{margin:'5px'}} className="add-client-form edit-settings">
          <label htmlFor="mainPage">Select action button [<i>Disabled Temporarily</i>]</label>
          <select id="actionButton" disabled value={actionButton} onChange={handleActionButtonChange}>
            <option value="add-casino">Add Casino</option>
            <option value="disabled">Disabled</option>
          </select>
        </div>
        </div>
        <br/>

        <h2>Navbar Icons Module</h2>
        <div style={{display:'flex',flexDirection:'row'}} >
        <div style={{ margin: '5px' }} className="add-client-form edit-settings">
          <label htmlFor="navbarIconsEnabled">Navbar Icons</label>
          <select id="navbarIconsEnabled" value={navbarIconsEnabled} onChange={handleNavbarIconsEnabledChange}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
        </div>
        <br/>

        </>
        )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
