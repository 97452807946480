// src/pages/SiteManager.tsx

import React, { useState, useEffect } from 'react';
import { collection, updateDoc, addDoc, doc, setDoc, getDocs, deleteDoc, onSnapshot } from 'firebase/firestore';
import { dbCasinos } from '../../App';

// Define the types for your data structures

interface ISite {
  siteId: string;
  siteName: string;
  lastmodifiedDate: Date;
  geos: IGeo[];
}
type ItemType = 'site' | 'geo' | 'page';
interface IGeo {
    geoId: string;
    geoDetails: string;
    lastmodifiedDate: Date;
    pages: IPage[];
  }

interface IPage {
    pageId: string;
    pageName: string;
    lastmodifiedDate: Date;
  }

const AdminSiteManager: React.FC = () => {

  //Confirm Delete Vars
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
const [itemToDelete, setItemToDelete] = useState({ type: '', siteId: '', geoId: '', pageId: '' });

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && showDeleteConfirmation) {
    setShowDeleteConfirmation(false);
  }
};


    //Sets and gets sites
  const [sites, setSites] = useState<ISite[]>([]);
  //SiteName Management
  const [newSiteName, setNewSiteName] = useState('');
  //SiteID Management
  const [newSiteId, setNewSiteId] = useState('');
  //Geo Name Management
  const [newGeoDetails, setNewGeoDetails] = useState('');
  //Geo ID Management
  const [newGeoId, setNewGeoId] = useState('');
  //Setting Selected Site
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  
  const [newPageId, setNewPageId] = useState('');
const [newPageName, setNewPageName] = useState('');
const [selectedGeoId, setSelectedGeoId] = useState<string | null>(null);


const [showAddSiteForm, setShowAddSiteForm] = useState(false);
const [showAddGeoForm, setShowAddGeoForm] = useState(false);
const [showAddPageForm, setShowAddPageForm] = useState(false);
const toggleAddSiteForm = () => {
  setShowAddSiteForm(prev => !prev);
  setShowAddGeoForm(false);
  setShowAddPageForm(false);
};

const toggleAddGeoForm = () => {
  setShowAddGeoForm(prev => !prev);
  setShowAddSiteForm(false);
  setShowAddPageForm(false);
};

const toggleAddPageForm = () => {
  setShowAddPageForm(prev => !prev);
  setShowAddSiteForm(false);
  setShowAddGeoForm(false);
};

const closeAllForms = () => {
  setShowAddPageForm(false);
  setShowAddSiteForm(false);
  setShowAddGeoForm(false);
};

useEffect(() => {
  if (showDeleteConfirmation) {
    document.addEventListener('keydown', handleKeyDown);
  } else {
    document.removeEventListener('keydown', handleKeyDown);
  }

  // Cleanup function to remove the event listener
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, [showDeleteConfirmation]);

useEffect(() => {
  fetchSites();
}, []);

  //Confirm Delete Action
  const confirmDeletion = () => {
    if (itemToDelete.type === 'site') {
      deleteSite(itemToDelete.siteId);
    } else if (itemToDelete.type === 'geo') {
      deleteGeo(itemToDelete.siteId, itemToDelete.geoId);
    } else if (itemToDelete.type === 'page') {
      deletePage(itemToDelete.siteId, itemToDelete.geoId, itemToDelete.pageId);
    }
    setShowDeleteConfirmation(false);
  };
  
  const promptDelete = (type: ItemType, siteId: string, geoId: string = '', pageId: string = '') => {
    setItemToDelete({ type, siteId, geoId, pageId });
    setShowDeleteConfirmation(true);
  };
  
  

  
  const fetchSites = async () => {
    setLoading(true);
    try {
      const sitesCollection = collection(dbCasinos, 'sites');
      const sitesSnapshot = await getDocs(sitesCollection);
      const sitesList: ISite[] = await Promise.all(sitesSnapshot.docs.map(async (siteDoc) => {
        const siteData = siteDoc.data();
        const geosCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos`);
        const geosSnapshot = await getDocs(geosCollection);
        const geos: IGeo[] = await Promise.all(geosSnapshot.docs.map(async (geoDoc) => {
          const geoData = geoDoc.data();
          const pagesCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos/${geoDoc.id}/pages`);
          const pagesSnapshot = await getDocs(pagesCollection);
          const pages: IPage[] = pagesSnapshot.docs.map(pageDoc => {
            const pageData = pageDoc.data();
            return {
              pageId: pageDoc.id,
              pageName: pageData.pageName,
              lastmodifiedDate: pageData.lastmodifiedDate // Ensure this field exists in Firestore
              // Add other fields from IPage if they exist
            };
          });
          return {
            geoId: geoDoc.id,
            geoDetails: geoData.geoDetails,
            lastmodifiedDate: geoData.lastmodifiedDate, // Ensure this field exists in Firestore
            pages
            // Add other fields from IGeo if they exist
          };
        }));
        return {
          siteId: siteDoc.id,
          siteName: siteData.siteName, // Ensure this field exists in Firestore
          lastmodifiedDate: siteData.lastmodifiedDate,
          geos
        };
      }));
      setSites(sitesList);
    } catch (error) {
      console.error("Error fetching sites: ", error);
      alert('Error fetching sites. Please try again.');
    }
    setLoading(false);
  };

  
  

  const addSite = async () => {
    setLoading(true);
    closeAllForms();
    if (!newSiteName.trim() || !newSiteId.trim()) {
      alert('Site name and ID cannot be empty!');
      return;
    }
  
    try {
      const siteRef = doc(dbCasinos, 'sites', newSiteId);
      await setDoc(siteRef, { siteName: newSiteName });
      const currentTimestamp = Date.now();
      setSites(prevSites => [...prevSites, { siteId: newSiteId, siteName: newSiteName, lastmodifiedDate: new Date(currentTimestamp) , geos: [] }]);
      setNewSiteName('');
      setNewSiteId('');
    } catch (error) {
      console.error("Error adding site: ", error);
      alert('Error adding site. Please try again.');
    }
    setLoading(false);
  };

  const addGeoToSite = async (siteId: string) => {
    setLoading(true);
    closeAllForms();
    if (!newGeoDetails.trim() || !newGeoId.trim()) {
      alert('Geo details and ID cannot be empty!');
      return;
    }
  
    const currentTimestamp = new Date(); // Current timestamp

    try {
      // Add the new geo with timestamp
      const geoRef = doc(dbCasinos, `sites/${siteId}/geos`, newGeoId);
      await setDoc(geoRef, { 
        geoDetails: newGeoDetails,
        lastmodifiedDate: currentTimestamp // Add timestamp to geo
      });

      // Update the site's last modified date
      const siteRef = doc(dbCasinos, 'sites', siteId);
      await updateDoc(siteRef, { 
        lastmodifiedDate: currentTimestamp // Update site's timestamp
      });
  
      // Update the local state
      setSites(prevSites => prevSites.map(site => 
        site.siteId === siteId ? { 
          ...site, 
          lastmodifiedDate: currentTimestamp, // Update site's last modified date in state
          geos: [
            ...site.geos, 
            { geoId: newGeoId, geoDetails: newGeoDetails, lastmodifiedDate: currentTimestamp, pages: [] } // Include the timestamp in the new geo
          ]
        } : site
      ));

      setNewGeoId('');
      setNewGeoDetails('');
    } catch (error) {
      console.error("Error adding geo: ", error);
      alert('Error adding geo. Please try again.');
    }
    setLoading(false);
};


const addPageToGeo = async (siteId: string, geoId: string) => {
  setLoading(true);
  closeAllForms();
  if (!newPageName.trim() || !newPageId.trim()) {
    alert('Page name and ID cannot be empty!');
    return;
  }

  const currentTimestamp = new Date(); // Current timestamp

  try {
    // Add the new page with timestamp
    const pageRef = doc(dbCasinos, `sites/${siteId}/geos/${geoId}/pages`, newPageId);
    await setDoc(pageRef, { 
      pageName: newPageName,
      lastmodifiedDate: currentTimestamp // Add timestamp to page
    });

    // Update the geo's and site's last modified date
    const geoRef = doc(dbCasinos, `sites/${siteId}/geos`, geoId);
    const siteRef = doc(dbCasinos, 'sites', siteId);
    await updateDoc(geoRef, { lastmodifiedDate: currentTimestamp });
    await updateDoc(siteRef, { lastmodifiedDate: currentTimestamp });

    // Update the local state
    setSites(prevSites => prevSites.map(site => 
      site.siteId === siteId ? { 
        ...site, 
        lastmodifiedDate: currentTimestamp, // Update site's last modified date
        geos: site.geos.map(geo => 
          geo.geoId === geoId ? {
            ...geo,
            lastmodifiedDate: currentTimestamp, // Update geo's last modified date
            pages: [...geo.pages, { pageId: newPageId, pageName: newPageName, lastmodifiedDate: currentTimestamp }] // Include timestamp in new page
          } : geo
        )
      } : site
    ));

    setNewPageId('');
    setNewPageName('');
  } catch (error) {
    console.error("Error adding page: ", error);
    alert('Error adding page. Please try again.');
  }
  setLoading(false);
};


  const setNewSelectedSite = async (siteId: string) => {
    setSelectedGeoId(null);
    closeAllForms();
    setSelectedSiteId(siteId);
  }

  const deleteSite = async (siteId: string) => {
    closeAllForms();
    setSelectedGeoId(null);
    setSelectedSiteId(null);
    setLoading(true);
    try {
      // Delete the geo from Firestore
      await deleteDoc(doc(dbCasinos, `sites/`, siteId));
        setSelectedGeoId(null);
      // Update the local state to reflect the deletion
      fetchSites();
    } catch (error) {
      console.error("Error deleting site: ", error);
      alert('Error deleting site. Please try again.');
    }
  };

  const deleteGeo = async (siteId: string, geoId: string) => {
    setLoading(true);
    const currentTimestamp = new Date(); // Current timestamp

    try {
      // Delete the geo from Firestore
      await deleteDoc(doc(dbCasinos, `sites/${siteId}/geos`, geoId));

      // Update the site's last modified date
      const siteRef = doc(dbCasinos, 'sites', siteId);
      await updateDoc(siteRef, { lastmodifiedDate: currentTimestamp });

      setSelectedGeoId(null);

      // Update the local state to reflect the deletion and updated timestamp
      setSites(prevSites => prevSites.map(site => 
        site.siteId === siteId ? { 
          ...site, 
          lastmodifiedDate: currentTimestamp, // Update site's last modified date
          geos: site.geos.filter(geo => geo.geoId !== geoId)
        } : site
      ));
    } catch (error) {
      console.error("Error deleting geo: ", error);
      alert('Error deleting geo. Please try again.');
    }
    setLoading(false);
};

  
const deletePage = async (siteId: string, geoId: string, pageId: string) => {
  setLoading(true);
  closeAllForms();
  const currentTimestamp = new Date(); // Current timestamp

  try {
    // Delete the page from Firestore
    await deleteDoc(doc(dbCasinos, `sites/${siteId}/geos/${geoId}/pages`, pageId));

    // Update the site's and geo's last modified date
    const siteRef = doc(dbCasinos, 'sites', siteId);
    const geoRef = doc(dbCasinos, `sites/${siteId}/geos`, geoId);
    await updateDoc(siteRef, { lastmodifiedDate: currentTimestamp });
    await updateDoc(geoRef, { lastmodifiedDate: currentTimestamp });

    // Update the local state to reflect the deletion and updated timestamp
    setSites(prevSites => prevSites.map(site => 
      site.siteId === siteId ? { 
        ...site, 
        lastmodifiedDate: currentTimestamp, // Update site's last modified date
        geos: site.geos.map(geo => 
          geo.geoId === geoId ? {
            ...geo,
            lastmodifiedDate: currentTimestamp, // Update geo's last modified date
            pages: geo.pages.filter(page => page.pageId !== pageId)
          } : geo
        )
      } : site
    ));
  } catch (error) {
    console.error("Error deleting page: ", error);
    alert('Error deleting page. Please try again.');
  }
  setLoading(false);
};

  

  return (
    <div className="main">
      {showDeleteConfirmation && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>Are you sure you want to delete this item?</b></p>
      <button className='sitemanagerbutton selected' onClick={() => setShowDeleteConfirmation(false)}>CANCEL DELETION</button>
      <button className='sitemanagerbutton delete' onClick={() => confirmDeletion()}>DELETE</button>
    </div>
  </div>
)}

      <div className="head">
        <h1>Sites Management</h1>
        <button className='sitemanagerbutton add' onClick={toggleAddSiteForm}>Add Site</button>
        {showAddSiteForm && (
        <div className="add-site-form">
  <form className="form blob" onSubmit={(e) => {
    e.preventDefault();
    addSite();
  }}>
    <h3>Add New Site</h3>
    <input required
      type="text" 
      placeholder="New Site ID" 
      value={newSiteId} 
      onChange={(e) => setNewSiteId(e.target.value)} 
    />
    <input required
      type="text" 
      placeholder="New Site Name" 
      value={newSiteName} 
      onChange={(e) => setNewSiteName(e.target.value)} 
    />
    <button type="submit">Add Site</button>
  </form>
</div>
        )}

{loading ? <tr>
        <td colSpan={10} style={{textAlign: 'center', verticalAlign: 'middle', height: '150px'}}>
            <div className="loader"></div>
        </td>
    </tr> :
<>
{sites.length > 0 ? (
    <>
          <div className="site-buttons-container">
          <h2>Sites</h2>
          {sites.map((site) => (
            <button 
              key={site.siteId} 
              onClick={() => setNewSelectedSite(site.siteId)}
              className={selectedSiteId === site.siteId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
            >
              {site.siteName}
            </button>
          ))}
          {selectedSiteId && (
            <>
            <div>
          <button className='sitemanagerbutton add' onClick={toggleAddGeoForm}>Add GEO</button>
          <button className='sitemanagerbutton delete' onClick={() => promptDelete('site', selectedSiteId)}>Delete Site</button>
          </div>
          </>
          )}
        </div>
          <div className="selected-site-container">
            {sites.filter(site => site.siteId === selectedSiteId).map((site) => (
              <div key={site.siteId}>
                
            {showAddGeoForm && (
                <div className="add-geo-form">
  <form className="form blob" onSubmit={(e) => {
    e.preventDefault();
    addGeoToSite(site.siteId);
  }}>
    <h3>Add new GEO</h3>
    <input required
      type="text" 
      placeholder="New Geo ID" 
      value={newGeoId} 
      onChange={(e) => setNewGeoId(e.target.value)} 
    />
    <input required
      type="text" 
      placeholder="Geo Details" 
      value={newGeoDetails} 
      onChange={(e) => setNewGeoDetails(e.target.value)} 
    />
    <button type="submit">Add GEO</button>
  </form>
</div>
            )}
              <h2>GEOs</h2>
                <div className="geo-buttons-container">
                {site.geos.length > 0 ? (
    site.geos.map((geo) => (
      <button 
        key={geo.geoId} 
        onClick={() => setSelectedGeoId(geo.geoId)}
        className={selectedGeoId === geo.geoId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
      >
        {geo.geoDetails}
      </button>
    ))
  ) : (
    <p>No GEOs found for this site.</p>
  )}
                  {selectedGeoId && (
            <>
            <div>
          <button className='sitemanagerbutton add' onClick={toggleAddPageForm}>Add Page</button>
          <button className='sitemanagerbutton delete'onClick={() => promptDelete('geo', site.siteId, selectedGeoId)}>Delete Geo</button>
          </div>
          </>)}
                </div>
  
                
                  <div className="selected-geo-container">
                    
                  {showAddPageForm && selectedGeoId &&(
                    <div className="add-page-form">
  <form className="form blob" onSubmit={(e) => {
    e.preventDefault();
    addPageToGeo(site.siteId, selectedGeoId);
  }}>
    <h3>Add Page</h3>
    <input required
      type="text" 
      placeholder="New Page ID" 
      value={newPageId} 
      onChange={(e) => setNewPageId(e.target.value)} 
    />
    <input required
      type="text" 
      placeholder="Page Name" 
      value={newPageName} 
      onChange={(e) => setNewPageName(e.target.value)} 
    />
    <button type="submit">Add Page</button>
  </form>
</div>
)}
{selectedGeoId && (
                  <div className="page-list-container">
                    <h3>Pages</h3>
                    {site.geos.map((geo) => (
  <div key={geo.geoId} className="geo-page-list-container">
    {selectedGeoId === geo.geoId && (
      geo.pages.length > 0 ? (
        geo.pages.map((page) => (
          <div key={page.pageId} className='sitemanagerpagecontainer'>
            <p>{page.pageName}</p>
            <button className='sitemanagerbutton delete' onClick={() => promptDelete('page', site.siteId, geo.geoId, page.pageId)}>Delete Page</button>
          </div>
        ))
      ) : (
        <p>No pages found for this GEO.</p>
      )
    )}
  </div>
))}


                </div>
)}
                  </div>
                
              </div>
            ))}
          </div>
          </>):(
            <p>No sites found</p>
          )}
          </>
          }
        {/* Additional components like search bar can be added here */}
      </div>
    </div>
  );
  
};

export default AdminSiteManager;



