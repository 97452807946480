import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../App';
import { doc, getDoc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from 'firebase/auth';


interface Params extends Record<string, string | undefined> {
  userId: string;
}

const EditUser: React.FC = () => {
  const params = useParams<Params>();
  const userId = params.userId || ''; // Note: I changed this from companyId to userId
  const navigate = useNavigate();


  const [loading, setLoading] = useState(false);
  const auth = getAuth();

  // Declare state for the user details
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [regDate, setRegDate] = useState<string>('');
  const [role, setRole] = useState<string>(''); // Role (either admin or user)
  const [position, setPosition] = useState<string>('');

  const [allowCasinoEditing, setAllowCasinoEditing] = useState<boolean>(false);
  const [allowSlotCodeEditing, setAllowSlotCodeEditing] = useState<boolean>(false);
  const [allowHreflangSetEditing, setAllowHreflangSetEditing] = useState<boolean>(false);
  const [allowBackupManagement, setAllowBackupManagement] = useState<boolean>(false);

  useEffect(() => {
    const userRef = doc(db, 'users', userId);
    getDoc(userRef).then(docSnapshot => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setName(data?.name);
        setSurname(data?.surname);
        setEmail(data?.email);
        setPosition(data?.position);
        setAllowCasinoEditing(data?.allowCasinoEditing);
        setAllowSlotCodeEditing(data?.allowSlotCodeEditing);
        setAllowHreflangSetEditing(data?.allowHreflangSetEditing);
        setAllowBackupManagement(data?.allowBackupManagement);

        if (data?.regDate) {
          const dateObj = data.regDate.toDate();
          const formattedDate = dateObj.toISOString().split('T')[0];
          setRegDate(formattedDate);
        }
        setRole(data?.role);
      }
    });
  }, [userId]);

  const handleSaveChanges = async () => {
    if (!name.trim() || !surname.trim() ||  !email.trim() || !position.trim()) {
      alert("Fields cannot be empty!");
      return;
    }
    console.log("AA: "+allowCasinoEditing);
    const regDateObj = new Date(regDate);
    const regTimestamp = Timestamp.fromDate(regDateObj);

    const userRef = doc(db, 'users', userId);
    const updatedData = {
      name,
      surname,
      email,
      regDate: regTimestamp,
      role,
      position,
      allowCasinoEditing: allowCasinoEditing,
      allowSlotCodeEditing: allowSlotCodeEditing,
      allowHreflangSetEditing: allowHreflangSetEditing,
      allowBackupManagement: allowBackupManagement
    };
    try {
      await updateDoc(userRef, updatedData);
      alert("User updated successfully!");
      navigate('/admin/users'); // Redirect back to user list or appropriate page
    } catch (error) {
      if (error instanceof Error) { // Type assertion
        alert("Error updating user: " + error.message);
      } else {
        alert("Error updating user.");
      }
    }
  };
  
  const deleteUserFunction = async (userUid: string) => {
    const functions = getFunctions();
    const deleteUser = httpsCallable(functions, 'deleteUser');
    
    try {
        const result = await deleteUser({ uid: userUid });
        
        // 2. Define a type for result.data
        const data = result.data as { message: string };
        console.log(data.message);
    } catch (error) {
        // 3. Type assertion for error
        if (error instanceof Error) {
            console.error("Error deleting user:", error.message);
        } else {
            console.error("Error deleting user:", error);
        }
    }
  }
  
  const handleDeleteUser = async () => {
    setLoading(true);
    if (!auth.currentUser) {
      console.error("No user is logged in. Cannot delete user.");
      return;
    }
  await deleteUserFunction(userId);
    
    // Refresh the users list
    navigate('/admin/users');

  }
  

  return (
    <div className="main">
      <div className="head">
        <h1>Modify User</h1>
        <div className="statcontainer">
          <div className="stat blob">
          <div className="form" style={{flexDirection:'column'}}>
            <label>User Name:</label>
            <input type="text" placeholder='John' value={name} onChange={(e) => setName(e.target.value)} />
            <label>User Surname:</label>
            <input type="text" placeholder='Doe' value={surname} onChange={(e) => setSurname(e.target.value)} />
            <label>User Email:</label>
            <input type="text" placeholder='johndoe@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
            
            <label>Registration Date:</label>
        <input type="date" value={regDate} onChange={(e) => setRegDate(e.target.value)} />

        <label>Position:</label>
        <select value={position} onChange={(e) => setPosition(e.target.value)}>
          <option value="Manager">Manager</option>
          <option value="User">User</option>
        </select>

        <label>Role:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </select>
        <div style={{display:'flex', flexDirection:'column', alignItems:'start'}}>
        <label>
            <input 
            style={{width:'fit-content'}}
              type="checkbox" 
              checked={allowCasinoEditing} 
              onChange={(e) => setAllowCasinoEditing(e.target.checked)} 
            /> Allow Casino Editing
          </label>
          <label>
            <input 
            style={{width:'fit-content'}}
              type="checkbox" 
              checked={allowSlotCodeEditing} 
              onChange={(e) => setAllowSlotCodeEditing(e.target.checked)} 
            /> Allow Slot Code Editing
          </label>
          <label>
            <input 
            style={{width:'fit-content'}}
              type="checkbox" 
              checked={allowHreflangSetEditing} 
              onChange={(e) => setAllowHreflangSetEditing(e.target.checked)} 
            /> Allow Hreflang Set Editing
          </label>
          <label>
            <input 
            style={{width:'fit-content'}}
              type="checkbox" 
              checked={allowBackupManagement} 
              onChange={(e) => setAllowBackupManagement(e.target.checked)} 
            /> Allow Backup Management
          </label>
          </div>
        <button onClick={handleSaveChanges}>Save Changes</button>
        <button onClick={handleDeleteUser} style={{ backgroundColor: 'red', marginTop: '5px' }}>
  Delete User
</button>


            
          </div>
          </div>
        </div>
        <button className='sitemanagerbutton' onClick={() => navigate(`/admin/users/`)}>Back to Users</button>
      </div>
    </div>
  );
};

export default EditUser;
