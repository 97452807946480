import { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../App';
import { UserContext } from '../context/UserContext';
import { useLoader } from '../context/LoaderContext';
import { useNotification } from '../context/NotificationContext';

interface Profile {
  email: string;
  name: string;
  surname: string;
  passwordSet: boolean;
  position: string;
  role: string;
  regdate: Date;
  allowCasinoEditing: boolean;
  allowSlotCodeEditing: boolean;
  allowHreflangSetEditing: boolean;
  allowBackupManagement: boolean;
  mainPage: string;
  actionButton: string;
  casinoEditing: string;
  slotCodeEditing: string;
  managingBackups: string;
  hreflangSetEditing: string;
  mainPageNotice: string;
  buttonOrder: string[];
  navbarIconsEnabled: string;
}

const LoginPage = () => {
  const { showNotification } = useNotification();
  const { showLoader, hideLoader } = useLoader();
  const { setUser, user } = useContext(UserContext);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [settingPassword, setSettingPassword] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [currentUserData, setCurrentUserData] = useState<Profile | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authing, setAuthing] = useState(false);
  const [successVisible, setSuccessVisible] = useState(true);

  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success');

  useEffect(() => {
    document.body.style.backgroundColor = '#3d0dd3';

    return () => {
      document.body.style.backgroundColor = '';
    };
  });

  useEffect(() => {
    if (success) {
      const newUrl = location.pathname + location.search.replace('?success=true', '');
      window.history.replaceState({}, '', newUrl);
      showNotification('Sign Up successful! Log in now!', 'success');
    }

    const checkUserState = async () => {
      if (currentUserData?.passwordSet === false) {
        console.log('Password not set, redirecting to set password.');
        setSettingPassword(true);
        return;
      }

      if (user?.role === 'admin') {
        navigate('/admin/');
      } else if (user?.role === 'user') {
        navigate('/console/');
      }
    };

    if (user) {
      checkUserState();
    }
  }, [user, success, location, currentUserData, navigate, showNotification, hideLoader]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showLoader();
    setSuccessVisible(false);
  
    if (!email) {
      showNotification('Email field is Empty!', 'error');
      hideLoader();
      return;
    }
    if (!password) {
      showNotification('Password field is Empty!', 'error');
      hideLoader();
      return;
    }
    setAuthing(true);
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user?.uid;
      setCurrentUserId(userId);
  
      if (userId) {
        const docRef = doc(db, `users/${userId}`);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const userData: Profile = docSnap.data() as Profile;
          setCurrentUserData(userData);
          setUser(userData);
  
          if (!userData.passwordSet) {
            console.log('Password not set for user, showing password set fields.');
            setSettingPassword(true);
            hideLoader();
            return;
          }
  
          if (userData.role === 'admin') {
            navigate('/admin/');
          } else if (userData.role === 'user') {
            navigate('/console/');
          }
        }
      }
    } catch (error: any) {
      handleAuthError(error);
      hideLoader(); // Ensure hideLoader() is called here
    } finally {
      setAuthing(false);
      hideLoader(); // Ensure hideLoader() is called here
    }
  };
  
  

  const handleAuthError = (error: any) => {
    hideLoader(); // Add this line to ensure the loader is hidden on error
    switch (error.code) {
      case 'auth/invalid-email':
        showNotification('Invalid email!', 'error');
        break;
      case 'auth/user-not-found':
        showNotification('Email does not exist!', 'error');
        break;
      case 'auth/wrong-password':
        showNotification('Incorrect password!', 'error');
        break;
      case 'auth/network-request-failed':
        showNotification('Cannot connect to server!', 'error');
        break;
      case 'auth/invalid-login-credentials':
        showNotification('Invalid Email/Password!', 'error');
        break;
      case 'auth/permission-denied':
        showNotification('Something went wrong, Contact nomble Support!', 'error');
        break;
      case 'auth/too-many-requests':
        showNotification('Too many requests. Try again later!', 'error');
        break;
      default:
        showNotification(error.message, 'error');
    }
  };
  

  const handleNewPasswordSubmit = async () => {
    showLoader();
    if (newPassword !== confirmPassword) {
      showNotification('Passwords do not match!', 'error');
      hideLoader();
      return;
    }

    if (!auth.currentUser) {
      showNotification('User is not authenticated. Please log in again!', 'warning');
      hideLoader();
      return;
    }

    try {
      await updatePassword(auth.currentUser, newPassword);

      if (currentUserId) {
        const docRef = doc(db, `users/${currentUserId}`);
        await setDoc(docRef, { passwordSet: true }, { merge: true });
      }

      setSettingPassword(false);

      if (currentUserData?.role === 'admin') {
        navigate('/admin/');
      } else if (currentUserData?.role === 'user') {
        navigate('/console/');
      }
    } catch (error: any) {
      showNotification('Failed to set new password. Please try again.!', 'error');
    } finally {
      hideLoader();
    }
    hideLoader();
  };

  if (settingPassword) {
    return (
      <div className="auth-page login">
        <div className="form blob login">
          <img className="form-logo" src="./logos/nomble-logo-blue.png" width={150} />
          <p style={{ marginBottom: "15px" }}>Please set your new password to continue.</p>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={['fas', 'lock']} className="input-icon" />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e?.target?.value ?? '')}
            />
          </div>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={['fas', 'lock']} className="input-icon" />
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e?.target?.value ?? '')}
            />
          </div>
          <button onClick={handleNewPasswordSubmit}>Set Password</button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-page login">
      <div className="form blob login">
        <img className="form-logo" src="./logos/nomble-logo-blue.png" width={150} />
        {success && successVisible && (
          <p className="msg-success">Sign up successful! You can now sign in.</p>
        )}
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={['fas', 'envelope']} className="input-icon" />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e?.target?.value ?? '')}
            />
          </div>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={['fas', 'lock']} className="input-icon" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e?.target?.value ?? '')}
            />
          </div>
          <button type="submit" disabled={authing}>
            Login
            <i className="button-icon">
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
