import React, { useState, useEffect, useContext, useRef } from 'react';

import { query, Unsubscribe, collection, addDoc, doc, setDoc, getDocs, deleteDoc, onSnapshot, orderBy, where, updateDoc, runTransaction } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {FirebaseStorage} from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../universal/context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { dbCasinos } from '../../App';

import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';

const element = <FontAwesomeIcon icon={faSearch} />

interface ICasino {
  displayName: string; // delete this
  casinoId: string;
  bonusCode: string;
  depositBonusOffer: string;
  finalTerms: string;
  geoTerms: string;
image: string;
noDepositOffer: string;
onDeposit: string;
positionNumber: number;
smallTerms: string;
  trackingLink: string;
  PluginLink:string,
  casinoName: string; /// this has to be displayed
}

interface ISite {
  siteId: string;
  siteName: string;
  lastmodifiedDate: Date;
  geos: IGeo[];
}
type ItemType = 'site' | 'geo' | 'page';

interface IGeo {
    geoId: string;
    geoDetails: string;
    pages: IPage[];
  }

interface IPage {
    pageId: string;
    pageName: string;
    casinos: ICasino[];
  }

let UnassignedCheck: boolean = false;

//let SearchCasinoCopy: ICasino[] = [];

let unasignedCasino: any = [];
let assignedCasino:any = [];

let SearchBtn: boolean = false;

const CasinoListPage = () => {
  
  const { showLoader, hideLoader } = useLoader();
  const { showNotification } = useNotification();

    //these are used to restore the Site/geo/page the user is on when returning from an edit casino page
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const foundSiteId = searchParams.get('site') || null;
  const foundGeoId = searchParams.get('geo') || null;
  const foundPageId = searchParams.get('page') || null;
  const nofetch = searchParams.get('nofetch') || null;
  
  //console.log(foundSiteId, foundGeoId, foundPageId, nofetch, 'check');
  
  const { user, setUser } = useContext(UserContext);
  const [sites, setSites] = useState<ISite[]>([]);
  const [casinos, setCasinos] = useState<ICasino[]>([]); 
  const [ShowUnassignedCasinos, setShowUnassignedCasinos] = useState<boolean>(false);   

const [showSitesChangedMessage, setShowSitesChangedMessage] = useState(false);
const [showMessage, setShowMessage] = useState(false);

 //Intial Calls
 useEffect(() => {
   //Check auths
   if (!user?.allowCasinoEditing) {
     navigate('/');
   }
 });

    const hideSitesChangedMessage = () => {
      setShowSitesChangedMessage(false);
      clearAllSelected();
    };

    const clearAllSelected = async () => {
      setSelectedNewPageId(null);
      setSelectedGeoId(null);
      setSelectedSiteId(null);
      fetchSites();
    };

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && showMessage) {
    hideMessage();
  }
};

const hideMessage = () => {
  setShowMessage(false);
};


useEffect(() => {
  if (showMessage) {
    document.addEventListener('keydown', handleKeyDown);
  } else {
    document.removeEventListener('keydown', handleKeyDown);
  }

  // Cleanup function to remove the event listener
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, [showMessage]);



  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [selectedGeoId, setSelectedGeoId] = useState<string | null>(null);
  const [selectedPageId, setSelectedNewPageId] = useState<string | null>(null);
    
  const [search, setSearch] = useState<string>('');


  const navigate = useNavigate();
  const auth = getAuth();

    const setNewSelectedSite = async (siteId: string) => {
    setShowUnassignedCasinos(false);
    setSelectedGeoId(null);
    setSelectedSiteId(siteId);
    setSelectedNewPageId(null);
    console.log("Check : " + 1);
    }
    

    const setSelectedPageIdSpecial = async (pageId: string) => {
      setShowUnassignedCasinos(false)
      setSelectedNewPageId(pageId);
      fetchSiteCasinoData(selectedSiteId, selectedGeoId, pageId);
      console.log("Check : " + 3 + " " + pageId);
    };
    
    const setFoundSelectedPageId = async (siteId: string, geoId: string, pageId: string) => {  // qui

        setSelectedSiteId(siteId);
        setNewSelectedGeo(geoId);
        setSelectedNewPageId(pageId)
      if (nofetch) {  

        setCasinos(assignedCasino);
      } else {

        fetchSiteCasinoData(siteId, geoId, pageId);
        console.log("Check : " + 3 + " " + pageId);
     }
    };
    
    const setNewSelectedGeo = async (geoId: string) => {
      setShowUnassignedCasinos(false)
      setSelectedGeoId(geoId);
      setSelectedNewPageId(null);
      console.log("Check : " + 4);
    };

  
  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => { /// this fun render geos data
    showLoader();
    try {
      const sitesCollection = collection(dbCasinos, 'sites');
      const sitesSnapshot = await getDocs(sitesCollection);
      const sitesList: ISite[] = await Promise.all(sitesSnapshot.docs.map(async (siteDoc) => {
        const siteData = siteDoc.data();
        const geosCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos`);
        const geosSnapshot = await getDocs(geosCollection);
        const geos: IGeo[] = await Promise.all(geosSnapshot.docs.map(async (geoDoc) => {
          const geoData = geoDoc.data();
          const pagesCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos/${geoDoc.id}/pages`);
          const pagesSnapshot = await getDocs(pagesCollection);
          const pages: IPage[] = pagesSnapshot.docs.map(pageDoc => {
            const pageData = pageDoc.data();
            return {
              pageId: pageDoc.id,
              pageName: pageData.pageName,
              casinos: []
              // Ensure this field exists in Firestore
              // Add other fields from IPage if they exist
            };
          });
          return {
            geoId: geoDoc.id,
            geoDetails: geoData.geoDetails, // Ensure this field exists in Firestore
            pages
            // Add other fields from IGeo if they exist
          };
        }));
        return {
          siteId: siteDoc.id,
          siteName: siteData.siteName,
          lastmodifiedDate: siteData.lastmodifiedDate, // Ensure this field exists in Firestore
          geos
        };
        
      }));
      setSites(sitesList);

    } catch (error) {
      console.error("Error fetching sites: ", error);
      alert('Error fetching sites. Please try again.');
    }
    if(foundSiteId!==null && foundGeoId!==null && foundPageId!== null){
      setFoundSelectedPageId(foundSiteId,foundGeoId,foundPageId);
    }
    hideLoader();
  };

    
    const fetchSiteCasinoData = async (selectedSiteId: string | null, selectedGEOId: string | null, selectedPageId: string | null) => {
    
    
      showLoader();
      
    if(selectedSiteId !=null){
    console.error("SITEID: "+selectedSiteId);
    }
    if(selectedGEOId !=null){
      console.error("GEOID: "+selectedGEOId);
      }
      if(selectedPageId !=null){
        console.error("PAGEID: "+selectedPageId) ;
        }
    try {
      
      const casinosCollection = collection(dbCasinos, `sites/${selectedSiteId}/geos/${selectedGEOId}/pages/${selectedPageId}/casinos`);
      const q = query(casinosCollection, orderBy('positionNumber')/*, where("positionNumber", ">", 0 )*/);
      const casinosSnapshot = await getDocs(q);
      
      unasignedCasino = [];
      assignedCasino = [];

      let casinos: ICasino[]  = casinosSnapshot.docs.map(casinoDoc => {
          
       // const casinoData = casinoDoc.data();
        if (casinoDoc.data().positionNumber < 1) {

          console.log('casinoDoc.data().positionNumber < 1')
           const UnassignedArray = (() => {
             return {
               ...casinoDoc.data(),
               casinoId: casinoDoc.id
             };
           });
          return unasignedCasino.push(UnassignedArray());
        } else {
        
          console.log('casinoDoc.data().positionNumber > 1')

        const assignedCasinos = (() => {
            return {
           
              ...casinoDoc.data(),
              casinoId: casinoDoc.id
            };
          });
          return assignedCasino.push(assignedCasinos());

        }
              /// this affects this page card
      });

    
      setCasinos(assignedCasino);
      
     // SearchCasinoCopy = assignedCasino //casinos;

    } catch (error) {
        console.error("Error fetching casinos: ", error);
        alert('Error fetching casinos. Please try again.');
    }
    hideLoader();
    };
    

    const UnassignCasino = (async (CasinoId: string, PosNr: number) => {

     UnassignedCheck = true;
     const casinosToUppdate = doc(dbCasinos, `sites/${selectedSiteId}/geos/${selectedGeoId}/pages/${selectedPageId}/casinos/${CasinoId}`);
     
      const UnassignNr = {
        positionNumber: 0
      };

      await updateDoc(casinosToUppdate, UnassignNr)
        //.then(()=>{ UnassignedCheck = true })
        .then(async () => {

          const casinosPath = `/sites/${selectedSiteId}/geos/${selectedGeoId}/pages/${selectedPageId}/casinos`;
          const casinosRef = collection(dbCasinos, casinosPath);
          const q = query(casinosRef, orderBy('positionNumber'), where('positionNumber', '>', PosNr));
          const querySnapshot = await getDocs(q);


          const affectedCasinos = querySnapshot.docs.map(doc => ({
            ref: doc.ref,
            data: doc.data() as ICasino
          }));

          await runTransaction(dbCasinos, async (transaction) => {

            for (const casino of affectedCasinos) { 

              const casinoData = casino.data;
              const newPosition = casinoData.positionNumber - 1;
              transaction.update(casino.ref, { positionNumber: newPosition });
            }
          }).then(() => fetchSiteCasinoData(selectedSiteId, selectedGeoId, selectedPageId))
            .catch((error) => [alert(error), UnassignedCheck = false]);
        }).catch(() => [UnassignedCheck = false, alert('Please try again to Unassign')]);
      
      UnassignedCheck=false
    });


    const searchQuery = () => {

      let checkCasinoArray: ICasino[] = [];
      if (search !== '') {

        if (ShowUnassignedCasinos) {
           checkCasinoArray = unasignedCasino?.filter((casInfos:ICasino) => {
            return casInfos.displayName.toLowerCase().includes(search.toLowerCase())
          })
        } else {
           checkCasinoArray = assignedCasino?.filter((casInfos:ICasino) => {
            return casInfos.displayName.toLowerCase().includes(search.toLowerCase())
          });
        }
      
         if (checkCasinoArray.length !== 0) { 
           SearchBtn = true
           setCasinos(checkCasinoArray);
         } else {
           SearchBtn = false;
          alert('casino not found') // console.log('not found')
         }
      };
      };

    const swichCasino = (() => {
      setCasinos([]);
      if (!ShowUnassignedCasinos) {
        setShowUnassignedCasinos(true)
        setCasinos(unasignedCasino)
      } else {
        setShowUnassignedCasinos(false)
      // setCasinos([]);
        setCasinos(assignedCasino)
      }
    });

    const backToCasinos = (() => {
      setCasinos([]);
      if (ShowUnassignedCasinos) {
        setCasinos(unasignedCasino);
      } else {
        setCasinos(assignedCasino);
      };
    });
  
   

  useEffect(() => {
  
  //let nr = 0
  //  console.log('useeffect')
    
  let unsubscribeFunc: Unsubscribe | null = null;
  let isInitialLoad = true;  // Flag to track the initial load
 
      if (selectedPageId) {
        console.log(selectedPageId || 'null page id','useeffect')
      const casinosPath = `/sites/${selectedSiteId}/geos/${selectedGeoId}/pages/${selectedPageId}/casinos`;
      const q = query(collection(dbCasinos, casinosPath));
      
    unsubscribeFunc =  onSnapshot(q, (snapshot) => {
     //  nr += 1;
       //console.log(nr)
      if ( isInitialLoad || UnassignedCheck /*isInitialLoad (Old code) */){
                isInitialLoad = false;  // After the first load, set the flag to false
                return;  // Skip the rest of the function during the initial load 
      } else {
        setShowMessage(true);
        setSelectedNewPageId(null); 
      }
         // Handle actual data changes here
      });
  }

  // Cleanup function
    return () => {
        if (unsubscribeFunc) {
            unsubscribeFunc();
        }
    };
    }, [selectedPageId]);
    

useEffect(() => {
  let unsubscribeFunc: Unsubscribe | null = null;
  let isInitialLoad = true; // Flag to track the initial load

  const sitesPath = `/sites/`;
  const q = query(collection(dbCasinos, sitesPath));

  unsubscribeFunc = onSnapshot(q, (snapshot) => {
      if (isInitialLoad) {
          isInitialLoad = false; // After the first load, set the flag to false
          return; // Skip the rest of the function during the initial load
      }

      // Handle actual data changes here
      console.log('check');
      setShowSitesChangedMessage(true);
  });

  // Cleanup function
  return () => {
      if (unsubscribeFunc) {
          unsubscribeFunc();
      }
  };
}, []); // Dependency array is empty to run only once on component mount
    

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser){
        setUser(null);
      }
    });
      

    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);
  
  useEffect(() => {
      if (user?.role === 'admin'){
        navigate('/adminapp');
      }
    }, [user]);

    return (
      <div style={{padding:'0'}} className="main">
        {showSitesChangedMessage && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>A GEO/Page has been created/deleted!</b></p>
      <button className='sitemanagerbutton selected' onClick={() => hideSitesChangedMessage()}>REFRESH PAGE</button>
    </div>
  </div>
)}
        {showMessage && (
  <div className="popup-overlay">
    <div className="delete-confirmation-popup">
      <p><b>Someone made a change to your current page!</b></p>
      <button className='sitemanagerbutton selected' onClick={() => hideMessage()}>OKAY</button>
    </div>
  </div>
)}
        <div className="dashpanel">
      <div className="main">
        <div className="head">
        <h1>Casino Manager</h1>
        <h2>
          View the current <span className="usertext">Casino List</span>.
        </h2>
              <div className="site-buttons-container"> 
                {sites.map((site) => ( 
                  <button 
                    key={site.siteId} 
                    onClick={() => setNewSelectedSite(site.siteId)}
                    className={selectedSiteId === site.siteId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                  >
                    {site.siteName}
                  </button>
                ))}
              </div>
    
              {sites.filter(site => site.siteId === selectedSiteId).map((site) => (
                <React.Fragment key={site.siteId}> {/* Key added here */}
                  <div className="geo-buttons-container">
                    <hr></hr>
                    {site.geos.length > 0 ? (
                      site.geos.map((geo) => (
                        <button 
                          key={geo.geoId} // Unique key for each geo button
                          onClick={() => setNewSelectedGeo(geo.geoId)}
                          className={selectedGeoId === geo.geoId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                        >
                          {geo.geoDetails}
                        </button>
                      ))
                    ) : (
                      <p>No GEOs found for this site.</p>
                    )}
                  </div>
    
                  <div className="page-list-container">
                  <hr></hr>
                    {site.geos.map((geo) => (
                      <div key={site.siteId + '-' + geo.geoId} className="geo-page-list-container"> {/* Unique key for each geo div */}
                        {selectedGeoId === geo.geoId && (
                          geo.pages.length > 0 ? (
                            geo.pages.map((page) => (
                              <button 
                                key={page.pageId + '-' + geo.geoId} // Unique key for each page button
                                onClick={() => setSelectedPageIdSpecial(page.pageId)}
                                className={selectedPageId === page.pageId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                              >
                                {page.pageName} 
                              </button>
                            ))
                          ) : (
                            <p>No pages found for this GEO.</p>
                          )
                        )}
                      </div>
                    ))}
                  </div>  
                </React.Fragment>
              ))}

          
    {selectedSiteId === 'ADLER' && selectedPageId && (<>Adlerslots In Development</>)}
          {selectedSiteId === 'ZMS' && selectedPageId && (<>Zamsino In Development</>)}
          {/*{selectedSiteId === 'BB' && selectedPageId && (<>BetterBonus</>)}*/}
          
          {(selectedSiteId === 'BB' /*|| selectedSiteId === 'TEST'*/) && selectedPageId &&(
            <>
            <hr></hr>
                        <br></br>
                       
                          
                          <div>
                            <button onClick={()=> searchQuery()} > <h2> Search {ShowUnassignedCasinos? 'Unassigned' :'Assigned' } Casino </h2></button> <br />
                            <input  type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                            {SearchBtn ?
                         <button className='' onClick={()=>backToCasinos() /*fetchSiteCasinoData(selectedSiteId, selectedGeoId,selectedPageId)*/} >back</button> : '' } 
                        
                          </div> 
                            {/* <i > {element} </i> */}
                         
                        <div className='wrapper'><button onClick={()=> swichCasino()} > <h2>show {ShowUnassignedCasinos?'Assigned':'Unassigned'} Casino</h2> </button></div>
          <h2>Casinos</h2>
                    <br></br>
                    {casinos.length > 0 ? (   
                   casinos.map((casino:any)  => (
                    /* <form className="new-booking-form" >*/ // form working but automatically render the page when we click on the unassign button
            <div className="cas-cont-wrapper cas-cont-box-100">
  <div className="cas-cont-wrapper-child cas-cont-box-100">
    <div className="cas-cont cas-cont-box-100">
      <div className="cas-left cas-cont-box-25">
        <div className="cas-img-cont-wrap cas-inner-pd cas-relative">
          <div className="cas-left-cont cas-cont-box-100">
            <div className="cas-img-container cas-relative">
              <div className="cas-pos-number">
              {casino.positionNumber}
              </div>
    <img
      className='cas-img'
      src={casino.image}
      alt="Selected"
      style={{ maxWidth: '150px', maxHeight: '100px' }}
    />
            </div>
            <div className="cas-accepted-txt cas-relative">
              <div className="cas-acc-text cas-cont-box-100 cas-relative">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
<g fill="#4dd82d" stroke="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g>
</svg> 
{casino.geoTerms}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cas-center cas-relative cas-cont-box-50">
        <div className="cas-inner-pd cas-relative cas-cont-box-50">
          <div className="cas-center-items cas-relative cas-cont-box-100">
            <p className="cas-bonus-text">No Deposit Offer</p>
            <p className="cas-bonus-text">{casino.noDepositOffer}</p>
            <p className="cas-terms-text">Bonus Code: {casino.bonusCode}</p>
          </div>
        </div>
        <div className="cas-inner-pd cas-relative cas-cont-box-50">
          <div className="cas-center-items cas-relative cas-cont-box-100">
            <p className="cas-bonus-text">Deposit Bonus Offer</p>
            <p className="cas-bonus-text">{casino.depositBonusOffer}</p>
            <p className="cas-terms-text">On Deposit: {casino.onDeposit}</p>
          </div>
        </div>
      </div>
      <div className="cas-right cas-relative cas-cont-box-25">
        <div className="cas-inner-pd-spec cas-relative cas-cont-box-100">
          <div className="cas-right-items cas-relative cas-cont-box-100">
            <div className="cas-claim-button cas-relative cas-text-center">
              <div className="cas-claim-btn cas-cont-box-100 cas-relative">
              <a className="ribbon-wrapper" title="EDIT CASINO" onClick={() => navigate(`/console/modify-casino/${casino.casinoId}?site=${selectedSiteId}&geo=${selectedGeoId}&page=${selectedPageId}`)}>
                                   
    <div className="glow"> &nbsp;</div>
    <span>EDIT CASINO</span> 
     </a> 

                <div className="cas-accepted-txt cas-relative">
                  <div className="cas-acc-text-btn cas-cont-box-100 cas-relative">
                  {casino.smallTerms}
                  </div> <br />
                  {ShowUnassignedCasinos ? '' : <button className='UnassignBtn' onClick={(e) => UnassignCasino(casino.casinoId, casino.positionNumber)} >Unassign</button>}                                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cas-terms-section cas-cont-box-100">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256"
>
<g fill="#2DBAD8" stroke="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.703,0 -23,10.297 -23,23c0,12.703 10.297,23 23,23c12.703,0 23,-10.297 23,-23c0,-12.703 -10.297,-23 -23,-23zM25,11c1.657,0 3,1.343 3,3c0,1.657 -1.343,3 -3,3c-1.657,0 -3,-1.343 -3,-3c0,-1.657 1.343,-3 3,-3zM29,38h-2h-4h-2v-2h2v-13h-2v-2h2h4v2v13h2z"></path></g></g>
</svg>
      <span className="cas-terms-section-text cas-cont-box-100">
      {casino.finalTerms}
      </span>
    </div>
                       </div> 
                       {/* <a href={casino.PluginLink}> Go to link </a> */}
                     </div>
                     
/* </form> */ // form working but automatically render the page when we click on the unassign button
))
):(<p>No casinos found for this page.</p>)}
</>

          )}
        </div>
        </div>
        </div>
      </div>
    );
    
};

export default CasinoListPage;