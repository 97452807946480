import React, { useState, useEffect, useContext } from 'react';
import { query, collection, getDocs, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../universal/context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { dbCasinos } from '../../App';
import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';

import axios from 'axios';

const element = <FontAwesomeIcon icon={faSearch} />;

interface ISite {
  siteId: string;
  siteName: string;
  lastmodifiedDate: Date;
  geos: IGeo[];
}

interface IGeo {
  geoId: string;
  geoDetails: string;
  pages: IPage[];
}

interface IPage {
  pageId: string;
  pageName: string;
}

const ClearCachePage: React.FC = () => {
  const { showLoader, hideLoader } = useLoader();
  const { showNotification } = useNotification();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const foundSiteId = searchParams.get('site');
  const foundGeoId = searchParams.get('geo');
  const foundPageId = searchParams.get('page');
  const nofetch = searchParams.get('nofetch');

  const { user, setUser } = useContext(UserContext);
  const [sites, setSites] = useState<ISite[]>([]);
  const [ShowUnassignedCasinos, setShowUnassignedCasinos] = useState(false);
  const [showSitesChangedMessage, setShowSitesChangedMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [selectedGeoId, setSelectedGeoId] = useState<string | null>(null);
  const [selectedPageId, setSelectedNewPageId] = useState<string | null>(null);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();
  const auth = getAuth();

 //Intial Calls
 useEffect(() => {
   //Check auths
   if (!user?.allowCasinoEditing) {
     navigate('/');
   }
 });

  const setNewSelectedSite = async (siteId: string) => {
    setShowUnassignedCasinos(false);
    setSelectedGeoId(null);
    setSelectedSiteId(siteId);
    setSelectedNewPageId(null);
    console.log("Check : " + 1);
  };

  const setSelectedPageIdSpecial = async (pageId: string) => {
    setShowUnassignedCasinos(false);
    setSelectedNewPageId(pageId);
    console.log("Check : " + 3 + " " + pageId);
  };

  const setFoundSelectedPageId = async (siteId: string, geoId: string, pageId: string) => {
    setSelectedSiteId(siteId);
    setNewSelectedGeo(geoId);
    setSelectedNewPageId(pageId);
  };

  const setNewSelectedGeo = async (geoId: string) => {
    setShowUnassignedCasinos(false);
    setSelectedGeoId(geoId);
    setSelectedNewPageId(null);
    console.log("Check : " + 4);
  };

  const fetchSites = async () => {
    showLoader();
    try {
      const sitesCollection = collection(dbCasinos, 'sites');
      const sitesSnapshot = await getDocs(sitesCollection);
      const sitesList = await Promise.all(sitesSnapshot.docs.map(async (siteDoc) => {
        const siteData = siteDoc.data();
        const geosCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos`);
        const geosSnapshot = await getDocs(geosCollection);
        const geos = await Promise.all(geosSnapshot.docs.map(async (geoDoc) => {
          const geoData = geoDoc.data();
          const pagesCollection = collection(dbCasinos, `sites/${siteDoc.id}/geos/${geoDoc.id}/pages`);
          const pagesSnapshot = await getDocs(pagesCollection);
          const pages = pagesSnapshot.docs.map(pageDoc => {
            const pageData = pageDoc.data();
            return {
              pageId: pageDoc.id,
              pageName: pageData.pageName,
            };
          });
          return {
            geoId: geoDoc.id,
            geoDetails: geoData.geoDetails,
            pages,
          };
        }));
        return {
          siteId: siteDoc.id,
          siteName: siteData.siteName,
          lastmodifiedDate: siteData.lastmodifiedDate,
          geos,
        };
      }));
      setSites(sitesList);
    } catch (error) {
      console.error("Error fetching sites: ", error);
      alert('Error fetching sites. Please try again.');
    }
    if (foundSiteId && foundGeoId && foundPageId) {
      setFoundSelectedPageId(foundSiteId, foundGeoId, foundPageId);
    }
    hideLoader();
  };

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user?.role === 'admin') {
      navigate('/adminapp');
    }
  }, [user]);

  const handleUpdateGeo = async (geoId: string, pages: IPage[]) => {
    const pairs = pages.map(page => ({ geo: geoId, page: page.pageId }));
    console.log("GEOUPDATEA: " + geoId + pages);
    await updateCache(pairs);
  };

  const handleUpdatePage = async (geoId: string, pageId: string) => {
    const pairs = [{ geo: geoId, page: pageId }];
    console.log("PAGEUPDATE: " + geoId + pageId);
    await updateCache(pairs);
  };

  const updateCache = async (pairs: { geo: string; page: string }[]) => {
    showLoader();
    try {
      await axios.post('https://betterbonus.com/wp-json/nombleapp/v1/update', { pairs }, {
        headers: {
          Authorization: 'Bearer cf8b06e9-c323-4693-b1ca-e1fcdaf60625',
          'Content-Type': 'application/json',
        },
      });
      showNotification('Cache updated successfully', 'success');
    } catch (error) {
      console.error("Error updating cache: ", error);
      showNotification('Error updating cache: ' + error, 'error');
    }
    hideLoader();
  };

  return (
    <div style={{ padding: '0' }} className="main">
      <div className="dashpanel">
        <div className="main">
          <div className="head">
            <h1>Casino Updater</h1>
            <h2>
              Update casino lists by <span className="usertext">GEOs/Pages</span>.
            </h2>
            <div className="site-buttons-container">
              {sites.map((site) => (
                <button
                  key={site.siteId}
                  onClick={() => setNewSelectedSite(site.siteId)}
                  className={selectedSiteId === site.siteId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                >
                  {site.siteName}
                </button>
              ))}
            </div>

            {sites.filter(site => site.siteId === selectedSiteId).map((site) => (
              <React.Fragment key={site.siteId}>
                <div className="geo-buttons-container">
                  <hr />
                  <div style={{display:'flex'}}>
                  {site.geos.length > 0 ? (
                    site.geos.map((geo) => (
                      <div key={geo.geoId} style={{display:'flex',flexDirection:'column',alignItems:'center'}} className="geo-container">
                        <button
                          onClick={() => setNewSelectedGeo(geo.geoId)}
                          className={selectedGeoId === geo.geoId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                        >
                          {geo.geoDetails}
                        </button>
                        <button
                          className="termsbuttonstyleadd"
                          onClick={() => handleUpdateGeo(geo.geoId, geo.pages)}
                        >
                          Update
                        </button>
                      </div>
                    ))
                  ) : (
                    <p>No GEOs found for this site.</p>
                  )}
                  </div>
                </div>

                <div className="page-list-container">
                  <hr />
                  {site.geos.map((geo) => (
                    <div key={geo.geoId} style={{display:'flex'}} className="geo-page-list-container">
                      {selectedGeoId === geo.geoId && (
                        geo.pages.length > 0 ? (
                          geo.pages.map((page) => (
                            <div key={page.pageId} className="page-container">
                              <button
                                onClick={() => setSelectedPageIdSpecial(page.pageId)}
                                className={selectedPageId === page.pageId ? "sitemanagerbutton selected" : "sitemanagerbutton"}
                              >
                                {page.pageName}
                              </button>
                              <button
                                className="termsbuttonstyleadd"
                                onClick={() => handleUpdatePage(geo.geoId, page.pageId)}
                              >
                                Update
                              </button>
                            </div>
                          ))
                        ) : (
                          <p>No pages found for this GEO.</p>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClearCachePage;
